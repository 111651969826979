/**
 * Export data as CSV from the selected data in a table
 * 
 * @template T - The type of data in the table.
 * 
 * @param {Object} options - The options for exporting.
 * @param {TableType<T>} options.table - The table from which to export the data.
 * @param {boolean} options.retrieveSelected - Whether to retrieve only the selected data.
 * 
 * @returns {Object} - An object containing the exporter function and the export status.
 * @property {Function} exporter - The function that performs the export.
 * @property {Object} exportStatus - The status of the export operation.
 * @property {string} exportStatus.loading - The loading status of the export operation.
 * @property {any} exportStatus.data - The exported data.
 * @property {any} exportStatus.error - The error that occurred during the export operation.
 */
import { Row, Table as TableType } from "@tanstack/react-table";
import { useCallback, useState } from "react";

import { camelCaseToNormal } from "lib/helpers";


const useGetHandlerExport = <T>({
  retrieveSelected,
  type
}: {
  retrieveSelected?: boolean;
  type: string
}) => {
  const [exportStatus, setExportStatus] = useState({
    loading: "initial",
    data: null,
    error: null,
  });

  const exporter = useCallback((table?: TableType<T>) => {
    setExportStatus({ loading: "loading", data: null, error: null });

  try {
      let data = "";
      const visibleColumns: string[] = [];

      for (const column of table?.getAllColumns() || []) {
        if (column.id === "checkbox") continue;

        data += camelCaseToNormal(column.id, true) + ", ";
        visibleColumns.push(column.id);
      }

      data = data.slice(0, data.lastIndexOf(","));

      data += "\n";

      let rows: Row<T>[] | undefined;

      if (retrieveSelected) {
        rows = table?.getSelectedRowModel().rows;
      } else {
        rows = table?.getRowModel().rows;
      }

      rows
        ?.map((row) => row.original)
        .forEach((row) => {
          const temp: string[] = [];

          for (const key in row) {
            if (visibleColumns.includes(key)) {
              if (Array.isArray(row[key as keyof typeof row])) {
                temp[visibleColumns.indexOf(key)] = (
                  row[key as keyof typeof row] as string[]
                ).join(" | ");
              } else {
                temp[visibleColumns.indexOf(key)] = row[
                  key as keyof typeof row
                ] as string;
              }
            }
          }

          temp[temp.length - 1] = temp[temp.length - 1].replace(", ", "");

          data += temp.join(", ") + "\n";
        });

      const blob = new Blob([data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `exported-${type}-payments-${Date.now()}.csv`;
      link.click();

      URL.revokeObjectURL(url);

      setExportStatus({ loading: "finish", data: null, error: null });
    } catch (error: any) {
      setExportStatus({ loading: "finish", data: null, error });
    }
  }, []);

  return {
    exporter,
    exportStatus,
  };
};

export { useGetHandlerExport };
