import { useTranslation } from "react-i18next";

import { Card } from "components/Card/Card";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Skeleton } from "components/Skeleton";
import { Tabs } from "components/Tabs";
import { Text } from "components/Text";

import { getStageStatus } from "lib/helpers";

import { CaseEventLog } from "../CaseEventLog";
import { CaseHistory } from "../CaseHistory/CaseHistory";
import { TablePayments } from "../TablePayments/TablePayments";
import styles from "./CaseInformation.module.scss";

type Props = {
  caseData?: SingleCaseRes;
  isLoading: boolean;
};

export function CaseInformation({ caseData, isLoading }: Props) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <section style={{ width: "56%" }}>
        <Skeleton width={"100%"} height={"100%"} />
      </section>
    );
  }

  if (!caseData) {
    return (
      <div style={{ paddingLeft: 24 }}>
        <Text as="p" style="body">
          Something went wrong, please ensure case/invoice exists. See console
          for more information.
        </Text>
      </div>
    );
  }

  return (
    <section style={{ width: "54%" }}>
      <Card>
        <div className={styles.caseInformationWrapper}>
          <header className={styles.caseInformationHeader}>
            <div>
              <Text as="h2" style="h2">
                Case ID
              </Text>
              <Pill text={caseData.invoice.invoiceNo} intent="Dark" />
            </div>

            <div>
              <Pill
                text={getStageStatus(caseData.invoice.status).caseStage}
                intent="Dark"
              />
              <Pill
                intent={
                  getStageStatus(caseData.invoice.status).caseStatus ===
                  "Active"
                    ? "Active"
                    : "Purple"
                }
                text={
                  <>
                    <Icon name="check" size={12} /> {caseData.invoice.status}
                  </>
                }
              />
            </div>
          </header>

          <section className={styles.caseInformationContent}>
            <Tabs
              tabs={[
                {
                  id: "eventLog",
                  title: t("pages.case.eventLogTabTitle"),
                  content: (
                    <CaseEventLog
                      invoiceNo={caseData.invoice.invoiceNo}
                      history={caseData.invoice.histories}
                    />
                  ),
                },
                {
                  id: "invoiceHistory",
                  title: t("pages.case.caseInvoiceHistoryTabTitle"),
                  content: <CaseHistory invoice={caseData.invoice} />,
                },
                {
                  id: "payments",
                  title: t("pages.case.paymentsTabTitle"),
                  content: (
                    <TablePayments payments={caseData.paymentsForInvoice} />
                  ),
                },
              ]}
            />
          </section>
        </div>
      </Card>
    </section>
  );
}
