import { SearchBox } from "components/Searchbox";

import { CardEventLog } from "./CardEventLog";

type Props = {
  invoiceNo: string;
  history: Case["histories"];
};

export function CaseEventLog({ invoiceNo, history }: Props) {
  return (
    <>
      <SearchBox placeholder="Search by..." onChange={() => undefined} />
      {history.map((event, i) => (
        <CardEventLog key={i} invoiceNo={invoiceNo} event={event} />
      ))}
    </>
  );
}
