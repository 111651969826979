import { createColumnHelper } from "@tanstack/react-table";

import { Pill } from "components/Pill";

import { getStageStatus, readableId } from "lib/helpers";

const columnHelper = createColumnHelper<Case>();

export const caseColumns = [
  columnHelper.accessor("customerName", {
    header: "Name",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usercirclesingle",
    },
  }),
  columnHelper.accessor("customerNo", {
    header: "Customer No",
    cell: (info) => (
      <Pill
        text={info.row.original.customerNo}
        intent="Link"
        href={`/customer/${info.row.original.customerId}`}
      />
    ),
    meta: {
      headerIcon: "userprofilefocus",
    },
  }),
  columnHelper.accessor("invoiceNo", {
    header: "Case ID",
    cell: (info) => (
      <Pill
        text={info.row.original.invoiceNo ?? readableId(info.row.original.id)}
        intent="Link"
        href={`/customer/${info.row.original.customerId}}/${info.row.original.id}`}
      />
    ),
    meta: {
      headerIcon: "streamlineCoreLineFileText",
    },
  }),
  columnHelper.accessor("countryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
    },
  }),
  columnHelper.accessor("dueDate", {
    header: "Due Date",
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    meta: {
      headerIcon: "blankcalendar",
    },
  }),
  columnHelper.accessor("totalAmount", {
    header: "TRB",
    cell: (info) => `${info.getValue()}`,
    meta: {
      headerIcon: "bagdollar",
    },
  }),
  columnHelper.display({
    id: "trb",
    header: "Total Remaining Balance",
    meta: {
      hidden: true,
      filterType: "text",
    },
  }),
  columnHelper.display({
    id: "profile",
    header: "Profile",
    meta: {
      hidden: true,
      filterType: "checkboxes",
      filterOptions: ["VIP", "Normal", "Default", "Customized"],
    },
  }),
  columnHelper.accessor("status", {
    id: "stage",
    header: "Stage",
    cell: (info) => (
      <p>
        {getStageStatus(info.row.original.status as InvoiceStatus).caseStage}
      </p>
    ),
    meta: {
      headerIcon: "stageClockHand",
      filterType: "checkboxes",
      filterOptions: ["Invoice", "Collection", "Lawyer"],
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => (
      <p>
        {getStageStatus(info.row.original.status as InvoiceStatus).caseStatus}
      </p>
    ),
    meta: {
      headerIcon: "streamLineFileCheck",
      filterType: "checkboxes",
      filterOptions: [
        "Active",
        "Cleared",
        "Cancelled",
        "Interrupted",
        "Paused",
      ],
    },
  }),
];
