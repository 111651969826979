export const HOST_PRESENTATION_SERVICE =
  process.env.REACT_APP_API_PRESENTATION_SERVICE ??
  "https://clients-service.inio-staging.com";

export const FILE_SERVICE = process.env.REACT_APP_API_FILE_SERVICE;

export const THEME = process.env.REACT_APP_THEME ?? "inio";
export const REACT_APP_THEME = THEME;
export const PROD = process.env.NODE_ENV === "production";
export const SARole = "inio-client-operator";
