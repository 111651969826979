import { ReactComponent as ButtonPause } from "./Button-Pause-2--Streamline-Core 1.svg";
import { ReactComponent as copy } from "./Copy--Streamline-Core-1.svg";
import { ReactComponent as streamlineCoreDownload } from "./Streamline-Core--Download.svg";
import { ReactComponent as StreamlineCoreFileCancel } from "./Streamline-Core-File-Cancel.svg";
import { ReactComponent as coreLineMoney } from "./Streamline-Core-Line-Money.svg";
import { ReactComponent as add } from "./add-1-68.svg";
import { ReactComponent as addbellnotification } from "./add-bell-notification-32.svg";
import { ReactComponent as addcircle } from "./add-circle-99.svg";
import { ReactComponent as addlayer } from "./add-layer-2-74.svg";
import { ReactComponent as addsquare } from "./add-square-10.svg";
import { ReactComponent as adobe } from "./adobe-33.svg";
import { ReactComponent as affordableandcleanenergy } from "./affordable-and-clean-energy-72.svg";
import { ReactComponent as aichipspark } from "./ai-chip-spark-2.svg";
import { ReactComponent as aicloudspark } from "./ai-cloud-spark-8.svg";
import { ReactComponent as aieditspark } from "./ai-edit-spark-17.svg";
import { ReactComponent as aiemailgeneratorspark } from "./ai-email-generator-spark-24.svg";
import { ReactComponent as aigamingspark } from "./ai-gaming-spark-78.svg";
import { ReactComponent as aigeneratelandscapeimagespark } from "./ai-generate-landscape-image-spark-45.svg";
import { ReactComponent as aigeneratemusicspark } from "./ai-generate-music-spark-50.svg";
import { ReactComponent as aigenerateportraitimagespark } from "./ai-generate-portrait-image-spark-79.svg";
import { ReactComponent as aigeneratevariationspark } from "./ai-generate-variation-spark-46.svg";
import { ReactComponent as ainavigationspark } from "./ai-navigation-spark-57.svg";
import { ReactComponent as ainetworkspark } from "./ai-network-spark-4.svg";
import { ReactComponent as aipromptspark } from "./ai-prompt-spark-28.svg";
import { ReactComponent as airedospark } from "./ai-redo-spark-88.svg";
import { ReactComponent as aisciencespark } from "./ai-science-spark-71.svg";
import { ReactComponent as aisettingsspark } from "./ai-settings-spark-97.svg";
import { ReactComponent as aitechnologyspark } from "./ai-technology-spark-52.svg";
import { ReactComponent as aiupscalespark } from "./ai-upscale-spark-95.svg";
import { ReactComponent as aivehiclespark } from "./ai-vehicle-spark-1-94.svg";
import { ReactComponent as airplane } from "./airplane-96.svg";
import { ReactComponent as airplanedisabled } from "./airplane-disabled-49.svg";
import { ReactComponent as airplaneenabled } from "./airplane-enabled-63.svg";
import { ReactComponent as airportplane } from "./airport-plane-87.svg";
import { ReactComponent as airportplanetransit } from "./airport-plane-transit-22.svg";
import { ReactComponent as airportsecurity } from "./airport-security-29.svg";
import { ReactComponent as alarmclock } from "./alarm-clock-49.svg";
import { ReactComponent as alertCircle } from "./alert-circle.svg";
import { ReactComponent as alien } from "./alien-49.svg";
import { ReactComponent as alignback } from "./align-back-1-43.svg";
import { ReactComponent as aligncenter } from "./align-center-28.svg";
import { ReactComponent as alignfront } from "./align-front-1-23.svg";
import { ReactComponent as alignleft } from "./align-left-87.svg";
import { ReactComponent as alignright } from "./align-right-78.svg";
import { ReactComponent as alt } from "./alt-63.svg";
import { ReactComponent as amazon } from "./amazon-4.svg";
import { ReactComponent as ambulance } from "./ambulance-58.svg";
import { ReactComponent as ampersand } from "./ampersand-62.svg";
import { ReactComponent as anchor } from "./anchor-30.svg";
import { ReactComponent as android } from "./android-47.svg";
import { ReactComponent as announcementmegaphone } from "./announcement-megaphone-21.svg";
import { ReactComponent as appstore } from "./app-store-10.svg";
import { ReactComponent as apple } from "./apple-35.svg";
import { ReactComponent as applicationadd } from "./application-add-92.svg";
import { ReactComponent as archivebox } from "./archive-box-66.svg";
import { ReactComponent as arrowbendleftdown } from "./arrow-bend-left-down-2-42.svg";
import { ReactComponent as arrowbendrightdown } from "./arrow-bend-right-down-2-57.svg";
import { ReactComponent as arrowcrossoverdown } from "./arrow-crossover-down-3.svg";
import { ReactComponent as arrowcrossoverleft } from "./arrow-crossover-left-83.svg";
import { ReactComponent as arrowcrossoverright } from "./arrow-crossover-right-47.svg";
import { ReactComponent as arrowcrossoverup } from "./arrow-crossover-up-38.svg";
import { ReactComponent as arrowcursor } from "./arrow-cursor-1-63.svg";
import { ReactComponent as arrowcursor1 } from "./arrow-cursor-2-29.svg";
import { ReactComponent as arrowcurvyupdown } from "./arrow-curvy-up-down-1-60.svg";
import { ReactComponent as arrowcurvyupdown1 } from "./arrow-curvy-up-down-2-11.svg";
import { ReactComponent as arrowdown } from "./arrow-down-2-54.svg";
import { ReactComponent as arrowdowndashedsquare } from "./arrow-down-dashed-square-55.svg";
import { ReactComponent as arrowexpand } from "./arrow-expand-95.svg";
import { ReactComponent as arrowinfiniteloop } from "./arrow-infinite-loop-35.svg";
import { ReactComponent as arrowmove } from "./arrow-move-67.svg";
import { ReactComponent as arrowreloadhorizontal } from "./arrow-reload-horizontal-1-44.svg";
import { ReactComponent as arrowreloadhorizontal1 } from "./arrow-reload-horizontal-2-53.svg";
import { ReactComponent as arrowreloadvertical } from "./arrow-reload-vertical-1-51.svg";
import { ReactComponent as arrowreloadvertical1 } from "./arrow-reload-vertical-2-18.svg";
import { ReactComponent as arrowroadmap } from "./arrow-roadmap-82.svg";
import { ReactComponent as arrowroundleft } from "./arrow-round-left-75.svg";
import { ReactComponent as arrowroundright } from "./arrow-round-right-33.svg";
import { ReactComponent as arrowshrink } from "./arrow-shrink-7.svg";
import { ReactComponent as arrowshrinkdiagonal } from "./arrow-shrink-diagonal-1-93.svg";
import { ReactComponent as arrowshrinkdiagonal1 } from "./arrow-shrink-diagonal-2-69.svg";
import { ReactComponent as arrowtransferdiagonal } from "./arrow-transfer-diagonal-1-41.svg";
import { ReactComponent as arrowtransferdiagonal1 } from "./arrow-transfer-diagonal-2-71.svg";
import { ReactComponent as arrowtransferdiagonal2 } from "./arrow-transfer-diagonal-3-94.svg";
import { ReactComponent as arrowup } from "./arrow-up-1-19.svg";
import { ReactComponent as arrowupdashedsquare } from "./arrow-up-dashed-square-40.svg";
import { ReactComponent as artificialintelligencespark } from "./artificial-intelligence-spark-39.svg";
import { ReactComponent as ascendingnumberorder } from "./ascending-number-order-9.svg";
import { ReactComponent as asterisk } from "./asterisk-1-71.svg";
import { ReactComponent as attribution } from "./attribution-91.svg";
import { ReactComponent as autoflash } from "./auto-flash-17.svg";
import { ReactComponent as backcamera } from "./back-camera-1-15.svg";
import { ReactComponent as backpack } from "./backpack-82.svg";
import { ReactComponent as bacteriaviruscellsbiology } from "./bacteria-virus-cells-biology-28.svg";
import { ReactComponent as bag } from "./bag-99.svg";
import { ReactComponent as bagdollar } from "./bag-dollar-1.svg";
import { ReactComponent as bagpound } from "./bag-pound-64.svg";
import { ReactComponent as bagrupee } from "./bag-rupee-37.svg";
import { ReactComponent as bagsuitcase } from "./bag-suitcase-1-34.svg";
import { ReactComponent as bagsuitcase1 } from "./bag-suitcase-2-39.svg";
import { ReactComponent as bagyen } from "./bag-yen-81.svg";
import { ReactComponent as baggage } from "./baggage-71.svg";
import { ReactComponent as ball } from "./ball-8.svg";
import { ReactComponent as balloon } from "./balloon-59.svg";
import { ReactComponent as bandage } from "./bandage-10.svg";
import { ReactComponent as bank } from "./bank-12.svg";
import { ReactComponent as batteryalert } from "./battery-alert-1-13.svg";
import { ReactComponent as batterycharging } from "./battery-charging-95.svg";
import { ReactComponent as batteryempty } from "./battery-empty-1-36.svg";
import { ReactComponent as batteryempty1 } from "./battery-empty-2-17.svg";
import { ReactComponent as batteryfull } from "./battery-full-1-89.svg";
import { ReactComponent as batterylow } from "./battery-low-1-24.svg";
import { ReactComponent as batterymedium } from "./battery-medium-1-69.svg";
import { ReactComponent as beach } from "./beach-73.svg";
import { ReactComponent as beanie } from "./beanie-97.svg";
import { ReactComponent as beermug } from "./beer-mug-18.svg";
import { ReactComponent as beerpitch } from "./beer-pitch-62.svg";
import { ReactComponent as bicyclebike } from "./bicycle-bike-28.svg";
import { ReactComponent as bill } from "./bill-1-10.svg";
import { ReactComponent as bill1 } from "./bill-2-26.svg";
import { ReactComponent as bill2 } from "./bill-4-23.svg";
import { ReactComponent as billcashless } from "./bill-cashless-29.svg";
import { ReactComponent as binancecircle } from "./binance-circle-6.svg";
import { ReactComponent as bitcoin } from "./bitcoin-86.svg";
import { ReactComponent as blankcalendar } from "./blank-calendar-30.svg";
import { ReactComponent as blanknotepad } from "./blank-notepad-96.svg";
import { ReactComponent as blockbellnotification } from "./block-bell-notification-6.svg";
import { ReactComponent as bloodbagdonation } from "./blood-bag-donation-75.svg";
import { ReactComponent as blooddonatedrop } from "./blood-donate-drop-77.svg";
import { ReactComponent as blooddropdonation } from "./blood-drop-donation-16.svg";
import { ReactComponent as bluetooth } from "./bluetooth-99.svg";
import { ReactComponent as bluetoothdisabled } from "./bluetooth-disabled-64.svg";
import { ReactComponent as bluetoothsearching } from "./bluetooth-searching-40.svg";
import { ReactComponent as bomb } from "./bomb-90.svg";
import { ReactComponent as bone } from "./bone-65.svg";
import { ReactComponent as bookreading } from "./book-reading-91.svg";
import { ReactComponent as bookmark } from "./bookmark-2.svg";
import { ReactComponent as bow } from "./bow-4.svg";
import { ReactComponent as bowtie } from "./bow-tie-90.svg";
import { ReactComponent as boxsign } from "./box-sign-63.svg";
import { ReactComponent as bracescircle } from "./braces-circle-31.svg";
import { ReactComponent as bracket } from "./bracket-44.svg";
import { ReactComponent as brailleblind } from "./braille-blind-16.svg";
import { ReactComponent as brain } from "./brain-43.svg";
import { ReactComponent as braincognitive } from "./brain-cognitive-57.svg";
import { ReactComponent as briefcasedollar } from "./briefcase-dollar-22.svg";
import { ReactComponent as brightness } from "./brightness-1-20.svg";
import { ReactComponent as brightness1 } from "./brightness-2-4.svg";
import { ReactComponent as brightness2 } from "./brightness-3-50.svg";
import { ReactComponent as brokenlink } from "./broken-link-2-17.svg";
import { ReactComponent as browseradd } from "./browser-add-5.svg";
import { ReactComponent as browserblock } from "./browser-block-43.svg";
import { ReactComponent as browserbuild } from "./browser-build-40.svg";
import { ReactComponent as browsercheck } from "./browser-check-36.svg";
import { ReactComponent as browserdelete } from "./browser-delete-45.svg";
import { ReactComponent as browserhash } from "./browser-hash-66.svg";
import { ReactComponent as browserlock } from "./browser-lock-80.svg";
import { ReactComponent as browsermultipleUwindow } from "./browser-multiple-window-62.svg";
import { ReactComponent as browserremove } from "./browser-remove-53.svg";
import { ReactComponent as browserUwebsite } from "./browser-website-1-90.svg";
import { ReactComponent as browserUwifi } from "./browser-wifi-58.svg";
import { ReactComponent as bug } from "./bug-41.svg";
import { ReactComponent as bugantivirusdebugging } from "./bug-antivirus-debugging-69.svg";
import { ReactComponent as bugantivirusshield } from "./bug-antivirus-shield-88.svg";
import { ReactComponent as bugvirusbrowser } from "./bug-virus-browser-71.svg";
import { ReactComponent as bugvirusdocument } from "./bug-virus-document-86.svg";
import { ReactComponent as bugvirusfolder } from "./bug-virus-folder-74.svg";
import { ReactComponent as building } from "./building-2-7.svg";
import { ReactComponent as bulletlist } from "./bullet-list-81.svg";
import { ReactComponent as burger } from "./burger-95.svg";
import { ReactComponent as burritofastfood } from "./burrito-fastfood-45.svg";
import { ReactComponent as bus } from "./bus-68.svg";
import { ReactComponent as businesscard } from "./business-card-88.svg";
import { ReactComponent as businesshandshake } from "./business-handshake.svg";
import { ReactComponent as businessideamoney } from "./business-idea-money-75.svg";
import { ReactComponent as businessprofessionhomeoffice } from "./business-profession-home-office-60.svg";
import { ReactComponent as businessprogressbar } from "./business-progress-bar-2-38.svg";
import { ReactComponent as businessusercurriculum } from "./business-user-curriculum-12.svg";
import { ReactComponent as buttonfastforward } from "./button-fast-forward-1-77.svg";
import { ReactComponent as buttonfastforward1 } from "./button-fast-forward-2-50.svg";
import { ReactComponent as buttonnext } from "./button-next-13.svg";
import { ReactComponent as buttonpause } from "./button-pause-2-57.svg";
import { ReactComponent as buttonplay } from "./button-play-28.svg";
import { ReactComponent as buttonpower } from "./button-power-1-44.svg";
import { ReactComponent as buttonprevious } from "./button-previous-53.svg";
import { ReactComponent as buttonrecord } from "./button-record-3-59.svg";
import { ReactComponent as buttonrewind } from "./button-rewind-1-11.svg";
import { ReactComponent as buttonrewind1 } from "./button-rewind-2-58.svg";
import { ReactComponent as buttonstop } from "./button-stop-92.svg";
import { ReactComponent as cakeslice } from "./cake-slice-22.svg";
import { ReactComponent as calculator } from "./calculator-1-3.svg";
import { ReactComponent as calculator1 } from "./calculator-2-55.svg";
import { ReactComponent as calendaradd } from "./calendar-add-65.svg";
import { ReactComponent as calendaredit } from "./calendar-edit-72.svg";
import { ReactComponent as calendarjumptodate } from "./calendar-jump-to-date-97.svg";
import { ReactComponent as calendarstar } from "./calendar-star-37.svg";
import { ReactComponent as callcentersupportservice } from "./call-center-support-service-80.svg";
import { ReactComponent as callhangup } from "./call-hang-up-27.svg";
import { ReactComponent as camera } from "./camera-1-32.svg";
import { ReactComponent as cameradisabled } from "./camera-disabled-56.svg";
import { ReactComponent as cameraloading } from "./camera-loading-99.svg";
import { ReactComponent as camerasquare } from "./camera-square-34.svg";
import { ReactComponent as cameravideo } from "./camera-video-7.svg";
import { ReactComponent as campingtent } from "./camping-tent-45.svg";
import { ReactComponent as candycane } from "./candy-cane.svg";
import { ReactComponent as cane } from "./cane-85.svg";
import { ReactComponent as cane1 } from "./cane.svg";
import { ReactComponent as capitol } from "./capitol-50.svg";
import { ReactComponent as carbatterycharging } from "./car-battery-charging-94.svg";
import { ReactComponent as cartaxi } from "./car-taxi-1-14.svg";
import { ReactComponent as cards } from "./cards-46.svg";
import { ReactComponent as cat } from "./cat-1-5.svg";
import { ReactComponent as cellularnetworkg } from "./cellular-network-4g.svg";
import { ReactComponent as cellularnetworkg1 } from "./cellular-network-5g-67.svg";
import { ReactComponent as cellularnetworklte } from "./cellular-network-lte-34.svg";
import { ReactComponent as celsius } from "./celsius-85.svg";
import { ReactComponent as chair } from "./chair-48.svg";
import { ReactComponent as champagnepartyalcohol } from "./champagne-party-alcohol-29.svg";
import { ReactComponent as chatbubbleoval } from "./chat-bubble-oval-64.svg";
import { ReactComponent as chatbubbleovalnotification } from "./chat-bubble-oval-notification-78.svg";
import { ReactComponent as chatbubbleovalsmiley } from "./chat-bubble-oval-smiley-1-66.svg";
import { ReactComponent as chatbubbleovalsmiley1 } from "./chat-bubble-oval-smiley-2-88.svg";
import { ReactComponent as chatbubblesquareblock } from "./chat-bubble-square-block-98.svg";
import { ReactComponent as chatbubblesquarequestion } from "./chat-bubble-square-question-23.svg";
import { ReactComponent as chatbubblesquareUwarning } from "./chat-bubble-square-warning-51.svg";
import { ReactComponent as chatbubblesquareUwrite } from "./chat-bubble-square-write-67.svg";
import { ReactComponent as chatbubbletextsquare } from "./chat-bubble-text-square-6.svg";
import { ReactComponent as chatbubbletypingoval } from "./chat-bubble-typing-oval-89.svg";
import { ReactComponent as chattwobubblesoval } from "./chat-two-bubbles-oval-2.svg";
import { ReactComponent as check } from "./check-39.svg";
import { ReactComponent as checkCircle } from "./check-circle.svg";
import { ReactComponent as checksquare } from "./check-square-58.svg";
import { ReactComponent as checkupmedicalreportclipboard } from "./checkup-medical-report-clipboard-87.svg";
import { ReactComponent as cheese } from "./cheese-30.svg";
import { ReactComponent as cherries } from "./cherries-61.svg";
import { ReactComponent as chessbishop } from "./chess-bishop-1.svg";
import { ReactComponent as chessking } from "./chess-king-71.svg";
import { ReactComponent as chessknight } from "./chess-knight-42.svg";
import { ReactComponent as chesspawn } from "./chess-pawn-82.svg";
import { ReactComponent as chevronDown } from "./chevron-down.svg";
import { ReactComponent as chevronLeft } from "./chevron-left.svg";
import { ReactComponent as chevronRight } from "./chevron-right.svg";
import { ReactComponent as chevronUp } from "./chevron-up.svg";
import { ReactComponent as chickengrilledstream } from "./chicken-grilled-stream-72.svg";
import { ReactComponent as christiancross } from "./christian-cross-1-85.svg";
import { ReactComponent as christiancross1 } from "./christian-cross-2-3.svg";
import { ReactComponent as christianity } from "./christianity-64.svg";
import { ReactComponent as chrome } from "./chrome-73.svg";
import { ReactComponent as circle } from "./circle-21.svg";
import { ReactComponent as circleclock } from "./circle-clock-46.svg";
import { ReactComponent as circleflask } from "./circle-flask-56.svg";
import { ReactComponent as cityhall } from "./city-hall-46.svg";
import { ReactComponent as classlesson } from "./class-lesson-26.svg";
import { ReactComponent as cleanUwaterandsanitation } from "./clean-water-and-sanitation-19.svg";
import { ReactComponent as clipboardadd } from "./clipboard-add-79.svg";
import { ReactComponent as clipboardcheck } from "./clipboard-check-86.svg";
import { ReactComponent as clipboardremove } from "./clipboard-remove-34.svg";
import { ReactComponent as closet } from "./closet-19.svg";
import { ReactComponent as cloud } from "./cloud-25.svg";
import { ReactComponent as cloudadd } from "./cloud-add-82.svg";
import { ReactComponent as cloudblock } from "./cloud-block-13.svg";
import { ReactComponent as cloudcheck } from "./cloud-check-6.svg";
import { ReactComponent as clouddatatransfer } from "./cloud-data-transfer-61.svg";
import { ReactComponent as cloudgaming } from "./cloud-gaming-1-32.svg";
import { ReactComponent as cloudrefresh } from "./cloud-refresh-14.svg";
import { ReactComponent as cloudshare } from "./cloud-share-19.svg";
import { ReactComponent as cloudUwarning } from "./cloud-warning-39.svg";
import { ReactComponent as cloudUwifi } from "./cloud-wifi-22.svg";
import { ReactComponent as clubssymbol } from "./clubs-symbol-51.svg";
import { ReactComponent as cocktail } from "./cocktail-93.svg";
import { ReactComponent as codeanalysis } from "./code-analysis-99.svg";
import { ReactComponent as codemonitor } from "./code-monitor-1-54.svg";
import { ReactComponent as codemonitor1 } from "./code-monitor-2-81.svg";
import { ReactComponent as coffeebean } from "./coffee-bean-9.svg";
import { ReactComponent as coffeemug } from "./coffee-mug-54.svg";
import { ReactComponent as coffeetakeawaycup } from "./coffee-takeaway-cup-40.svg";
import { ReactComponent as cog } from "./cog-12.svg";
import { ReactComponent as coinshare } from "./coin-share-71.svg";
import { ReactComponent as coinsstack } from "./coins-stack-31.svg";
import { ReactComponent as collaborationsidea } from "./collaborations-idea-92.svg";
import { ReactComponent as colorpalette } from "./color-palette-45.svg";
import { ReactComponent as colorpicker } from "./color-picker-84.svg";
import { ReactComponent as colorswatches } from "./color-swatches-16.svg";
import { ReactComponent as comet } from "./comet-11.svg";
import { ReactComponent as command } from "./command.svg";
import { ReactComponent as compassnavigator } from "./compass-navigator-55.svg";
import { ReactComponent as compositionoval } from "./composition-oval-72.svg";
import { ReactComponent as compositionvertical } from "./composition-vertical-54.svg";
import { ReactComponent as compsitionhorizontal } from "./compsition-horizontal-97.svg";
import { ReactComponent as computerchip } from "./computer-chip-1-78.svg";
import { ReactComponent as computerchip1 } from "./computer-chip-2-29.svg";
import { ReactComponent as computerpcdesktop } from "./computer-pc-desktop-54.svg";
import { ReactComponent as coneshape } from "./cone-shape-56.svg";
import { ReactComponent as contactphonebook } from "./contact-phonebook-2-94.svg";
import { ReactComponent as container } from "./container-25.svg";
import { ReactComponent as controller } from "./controller-1-34.svg";
import { ReactComponent as controller1 } from "./controller-55.svg";
import { ReactComponent as controllerUwireless } from "./controller-wireless-19.svg";
import { ReactComponent as convertpdf } from "./convert-pdf-2-26.svg";
import { ReactComponent as copypaste } from "./copy-paste-61.svg";
import { ReactComponent as creativecommons } from "./creative-commons-76.svg";
import { ReactComponent as creditcard } from "./credit-card-1-28.svg";
import { ReactComponent as creditcard1 } from "./credit-card-2-89.svg";
import { ReactComponent as cropselection } from "./crop-selection-5.svg";
import { ReactComponent as crown } from "./crown-89.svg";
import { ReactComponent as crutch } from "./crutch-39.svg";
import { ReactComponent as cssthree } from "./css-three-12.svg";
import { ReactComponent as curlybrackets } from "./curly-brackets-89.svg";
import { ReactComponent as cursorclick } from "./cursor-click-57.svg";
import { ReactComponent as customersupport } from "./customer-support-1-64.svg";
import { ReactComponent as cut } from "./cut-8.svg";
import { ReactComponent as cyborg1 } from "./cyborg-2-37.svg";
import { ReactComponent as cyborg } from "./cyborg-18.svg";
import { ReactComponent as dangerouszonesign } from "./dangerous-zone-sign-19.svg";
import { ReactComponent as darkdislaymode } from "./dark-dislay-mode-80.svg";
import { ReactComponent as dashboard } from "./dashboard-3-1.svg";
import { ReactComponent as dashboardcircle } from "./dashboard-circle-52.svg";
import { ReactComponent as database } from "./database-43.svg";
import { ReactComponent as databasecheck } from "./database-check-41.svg";
import { ReactComponent as databaselock } from "./database-lock-44.svg";
import { ReactComponent as databaserefresh } from "./database-refresh-14.svg";
import { ReactComponent as databaseremove } from "./database-remove-31.svg";
import { ReactComponent as databaseserver } from "./database-server-1-16.svg";
import { ReactComponent as databaseserver1 } from "./database-server-2-32.svg";
import { ReactComponent as databasesetting } from "./database-setting-8.svg";
import { ReactComponent as databasesubtractraidstoragecodediskprogrammingdatabasearrayharddiscminus } from "./database-subtract-2-raid-storage-code-disk-programming-database-array-hard-disc-minus-68.svg";
import { ReactComponent as decentUworkandeconomicgrowth } from "./decent-work-and-economic-growth-22.svg";
import { ReactComponent as definitionsearchbook } from "./definition-search-book-42.svg";
import { ReactComponent as delete1 } from "./delete-1-73.svg";
import { ReactComponent as deletekeyboard } from "./delete-keyboard-98.svg";
import { ReactComponent as descendingnumberorder } from "./descending-number-order-15.svg";
import { ReactComponent as desktopchat } from "./desktop-chat-86.svg";
import { ReactComponent as desktopcheck } from "./desktop-check-1.svg";
import { ReactComponent as desktopcode } from "./desktop-code-70.svg";
import { ReactComponent as desktopdelete } from "./desktop-delete-72.svg";
import { ReactComponent as desktopdollar } from "./desktop-dollar-84.svg";
import { ReactComponent as desktopemoji } from "./desktop-emoji-27.svg";
import { ReactComponent as desktopfavoritestar } from "./desktop-favorite-star-96.svg";
import { ReactComponent as desktopgame } from "./desktop-game-87.svg";
import { ReactComponent as desktophelp } from "./desktop-help-42.svg";
import { ReactComponent as devicedatabaseencryption } from "./device-database-encryption-1-50.svg";
import { ReactComponent as dhammajak } from "./dhammajak-2.svg";
import { ReactComponent as diamond } from "./diamond-2-45.svg";
import { ReactComponent as diamondssymbol } from "./diamonds-symbol-74.svg";
import { ReactComponent as dice } from "./dice-1-69.svg";
import { ReactComponent as dice1 } from "./dice-2-63.svg";
import { ReactComponent as dice2 } from "./dice-3-33.svg";
import { ReactComponent as dice3 } from "./dice-4-75.svg";
import { ReactComponent as dice4 } from "./dice-5-8.svg";
import { ReactComponent as dice5 } from "./dice-6-37.svg";
import { ReactComponent as dicesentertainmentgamingdices } from "./dices-entertainment-gaming-dices-47.svg";
import { ReactComponent as dictionarylanguagebook } from "./dictionary-language-book-62.svg";
import { ReactComponent as disablebellnotification } from "./disable-bell-notification.svg";
import { ReactComponent as disableheart } from "./disable-heart-88.svg";
import { ReactComponent as discord } from "./discord-66.svg";
import { ReactComponent as discountpercentbadge } from "./discount-percent-badge-36.svg";
import { ReactComponent as discountpercentcircle } from "./discount-percent-circle-96.svg";
import { ReactComponent as discountpercentcoupon } from "./discount-percent-coupon-47.svg";
import { ReactComponent as discountpercentcutout } from "./discount-percent-cutout-18.svg";
import { ReactComponent as discountpercentfire } from "./discount-percent-fire-52.svg";
import { ReactComponent as discussionconverstionreply } from "./discussion-converstion-reply-74.svg";
import { ReactComponent as divisioncircle } from "./division-circle-48.svg";
import { ReactComponent as dna } from "./dna-15.svg";
import { ReactComponent as dollarcoin } from "./dollar-coin-1-79.svg";
import { ReactComponent as dollarcoin1 } from "./dollar-coin-53.svg";
import { ReactComponent as donut } from "./donut-96.svg";
import { ReactComponent as downloadbox } from "./download-box-1-22.svg";
import { ReactComponent as downloadcircle } from "./download-circle-98.svg";
import { ReactComponent as downloadcomputer } from "./download-computer-24.svg";
import { ReactComponent as downloadfile } from "./download-file-70.svg";
import { ReactComponent as dressingtable } from "./dressing-table-84.svg";
import { ReactComponent as drone } from "./drone-76.svg";
import { ReactComponent as dropbox } from "./dropbox-94.svg";
import { ReactComponent as earhearing } from "./ear-hearing-15.svg";
import { ReactComponent as earpods } from "./earpods-19.svg";
import { ReactComponent as earth } from "./earth-1-36.svg";
import { ReactComponent as earthairplane } from "./earth-airplane-93.svg";
import { ReactComponent as earthGlobe } from "./earth-globe.svg";
import { ReactComponent as editimagephoto } from "./edit-image-photo-73.svg";
import { ReactComponent as eject } from "./eject-26.svg";
import { ReactComponent as electriccord } from "./electric-cord-1-15.svg";
import { ReactComponent as electriccord1 } from "./electric-cord-3-92.svg";
import { ReactComponent as emergencyexit } from "./emergency-exit-33.svg";
import { ReactComponent as emptyclipboard } from "./empty-clipboard-13.svg";
import { ReactComponent as epicgames } from "./epic-games-1-23.svg";
import { ReactComponent as equalsign } from "./equal-sign-36.svg";
import { ReactComponent as erlenmeyerflask } from "./erlenmeyer-flask-1.svg";
import { ReactComponent as esports } from "./esports-2.svg";
import { ReactComponent as ethereum } from "./ethereum-49.svg";
import { ReactComponent as ethereumcircle } from "./ethereum-circle-24.svg";
import { ReactComponent as euro } from "./euro-83.svg";
import { ReactComponent as expand } from "./expand-59.svg";
import { ReactComponent as expandhorizontal } from "./expand-horizontal-1-77.svg";
import { ReactComponent as expandUwindow } from "./expand-window-2-92.svg";
import { ReactComponent as eyeoptic } from "./eye-optic-46.svg";
import { ReactComponent as facescan } from "./face-scan-1-14.svg";
import { ReactComponent as facebook } from "./facebook-1-12.svg";
import { ReactComponent as factorial } from "./factorial-57.svg";
import { ReactComponent as fahrenheit } from "./fahrenheit-47.svg";
import { ReactComponent as fastforwardclock } from "./fastforward-clock-33.svg";
import { ReactComponent as figma } from "./figma-83.svg";
import { ReactComponent as fileaddalternate } from "./file-add-alternate-45.svg";
import { ReactComponent as filecode } from "./file-code-1-91.svg";
import { ReactComponent as filedeletealternate } from "./file-delete-alternate-91.svg";
import { ReactComponent as fileremovealternate } from "./file-remove-alternate-17.svg";
import { ReactComponent as filmroll } from "./film-roll-1-44.svg";
import { ReactComponent as filmslate } from "./film-slate-38.svg";
import { ReactComponent as filter } from "./filter-2-11.svg";
import { ReactComponent as fingerprint } from "./fingerprint-1-54.svg";
import { ReactComponent as fingerprint1 } from "./fingerprint-2-24.svg";
import { ReactComponent as firealarm } from "./fire-alarm-2-72.svg";
import { ReactComponent as fireextinguishersign } from "./fire-extinguisher-sign-52.svg";
import { ReactComponent as fireworksrocket } from "./fireworks-rocket-27.svg";
import { ReactComponent as fist } from "./fist-23.svg";
import { ReactComponent as fittoheightsquare } from "./fit-to-height-square-27.svg";
import { ReactComponent as flash } from "./flash-1-91.svg";
import { ReactComponent as flash1 } from "./flash-2-47.svg";
import { ReactComponent as flash2 } from "./flash-3-86.svg";
import { ReactComponent as flashoff } from "./flash-off-85.svg";
import { ReactComponent as flipverticalarrow } from "./flip-vertical-arrow-2-21.svg";
import { ReactComponent as flipverticalcircle } from "./flip-vertical-circle-1-56.svg";
import { ReactComponent as flipverticalsquare } from "./flip-vertical-square-2-61.svg";
import { ReactComponent as floppydisk } from "./floppy-disk-2.svg";
import { ReactComponent as flower } from "./flower-47.svg";
import { ReactComponent as flower1 } from "./flower-83.svg";
import { ReactComponent as flumask } from "./flu-mask-60.svg";
import { ReactComponent as focuspoints } from "./focus-points-13.svg";
import { ReactComponent as folderadd } from "./folder-add-29.svg";
import { ReactComponent as foldercheck } from "./folder-check-52.svg";
import { ReactComponent as folderdelete } from "./folder-delete-8.svg";
import { ReactComponent as forkknife } from "./fork-knife-15.svg";
import { ReactComponent as forkspoon } from "./fork-spoon-73.svg";
import { ReactComponent as fragile } from "./fragile-93.svg";
import { ReactComponent as frontcamera } from "./front-camera-68.svg";
import { ReactComponent as galaxy } from "./galaxy-1-38.svg";
import { ReactComponent as galaxy1 } from "./galaxy-2-99.svg";
import { ReactComponent as gameboy } from "./gameboy-49.svg";
import { ReactComponent as gasstationfuelpetroleum } from "./gas-station-fuel-petroleum-43.svg";
import { ReactComponent as genderequality } from "./gender-equality-51.svg";
import { ReactComponent as gifformat } from "./gif-format-7.svg";
import { ReactComponent as gift } from "./gift-2-15.svg";
import { ReactComponent as gift1 } from "./gift-57.svg";
import { ReactComponent as givegift } from "./give-gift-99.svg";
import { ReactComponent as glasses } from "./glasses-84.svg";
import { ReactComponent as globallearning } from "./global-learning-6.svg";
import { ReactComponent as gmail } from "./gmail-51.svg";
import { ReactComponent as gold } from "./gold-16.svg";
import { ReactComponent as goodhealthandUwellbeing } from "./good-health-and-well-being-29.svg";
import { ReactComponent as google } from "./google-80.svg";
import { ReactComponent as googledrive } from "./google-drive-93.svg";
import { ReactComponent as graduationcap } from "./graduation-cap-68.svg";
import { ReactComponent as gramophone } from "./gramophone-89.svg";
import { ReactComponent as graph } from "./graph-17.svg";
import { ReactComponent as grapharrowdecrease } from "./graph-arrow-decrease-93.svg";
import { ReactComponent as grapharrowincrease } from "./graph-arrow-increase-69.svg";
import { ReactComponent as graphbardecrease } from "./graph-bar-decrease-59.svg";
import { ReactComponent as graphbarincrease } from "./graph-bar-increase-66.svg";
import { ReactComponent as graphdot } from "./graph-dot-46.svg";
import { ReactComponent as groupmeetingcall } from "./group-meeting-call-83.svg";
import { ReactComponent as halfstar } from "./half-star-1-51.svg";
import { ReactComponent as handcursor } from "./hand-cursor-37.svg";
import { ReactComponent as handgrab } from "./hand-grab-9.svg";
import { ReactComponent as handheld } from "./hand-held-81.svg";
import { ReactComponent as handheldtabletdrawing } from "./hand-held-tablet-drawing-65.svg";
import { ReactComponent as handheldtabletUwriting } from "./hand-held-tablet-writing-75.svg";
import { ReactComponent as hangup } from "./hang-up-1-38.svg";
import { ReactComponent as hangup1 } from "./hang-up-2-65.svg";
import { ReactComponent as happyface } from "./happy-face-4.svg";
import { ReactComponent as harddisk } from "./hard-disk-52.svg";
import { ReactComponent as harddrive } from "./hard-drive-1-53.svg";
import { ReactComponent as headingparagraphstylesheading } from "./heading-1-paragraph-styles-heading-60.svg";
import { ReactComponent as headingparagraphstylesheading1 } from "./heading-2-paragraph-styles-heading-90.svg";
import { ReactComponent as headingparagraphstylesheading2 } from "./heading-3-paragraph-styles-heading-2.svg";
import { ReactComponent as healthcare } from "./health-care-2-33.svg";
import { ReactComponent as hearingdeaf } from "./hearing-deaf-1-44.svg";
import { ReactComponent as hearingdeaf1 } from "./hearing-deaf-2-56.svg";
import { ReactComponent as heart } from "./heart-72.svg";
import { ReactComponent as heartratepulsegraph } from "./heart-rate-pulse-graph-69.svg";
import { ReactComponent as heartratesearch } from "./heart-rate-search-1.svg";
import { ReactComponent as heartssymbol } from "./hearts-symbol-60.svg";
import { ReactComponent as helpchat } from "./help-chat-2.svg";
import { ReactComponent as helpquestion } from "./help-question-1-36.svg";
import { ReactComponent as hexagram } from "./hexagram-20.svg";
import { ReactComponent as hierarchy3 } from "./hierarchy-2-59.svg";
import { ReactComponent as hierarchy4 } from "./hierarchy-4-94.svg";
import { ReactComponent as hierarchy5 } from "./hierarchy-7-76.svg";
import { ReactComponent as hierarchy } from "./hierarchy-10-70.svg";
import { ReactComponent as hierarchy1 } from "./hierarchy-13-77.svg";
import { ReactComponent as hierarchy2 } from "./hierarchy-14-55.svg";
import { ReactComponent as highspeedtrainfront } from "./high-speed-train-front-53.svg";
import { ReactComponent as hinduism } from "./hinduism-65.svg";
import { ReactComponent as home } from "./home-3-30.svg";
import { ReactComponent as home1 } from "./home-4-95.svg";
import { ReactComponent as house } from "./home.svg";
import { ReactComponent as horizontalmenucircle } from "./horizontal-menu-circle-49.svg";
import { ReactComponent as hospitalsigncircle } from "./hospital-sign-circle-3.svg";
import { ReactComponent as hospitalsignsquare } from "./hospital-sign-square-35.svg";
import { ReactComponent as hotspring } from "./hot-spring-13.svg";
import { ReactComponent as hotelairconditioner } from "./hotel-air-conditioner-34.svg";
import { ReactComponent as hotelbed } from "./hotel-bed-2-70.svg";
import { ReactComponent as hotellaundry } from "./hotel-laundry-95.svg";
import { ReactComponent as hotelonestar } from "./hotel-one-star-42.svg";
import { ReactComponent as hotelshowerhead } from "./hotel-shower-head-1.svg";
import { ReactComponent as hoteltwostar } from "./hotel-two-star-24.svg";
import { ReactComponent as humiditynone } from "./humidity-none-6.svg";
import { ReactComponent as icecream } from "./ice-cream-2-26.svg";
import { ReactComponent as icecream1 } from "./ice-cream-3-70.svg";
import { ReactComponent as imageblur } from "./image-blur-40.svg";
import { ReactComponent as imagesaturation } from "./image-saturation-4.svg";
import { ReactComponent as inboxblock } from "./inbox-block-48.svg";
import { ReactComponent as inboxfavorite } from "./inbox-favorite-18.svg";
import { ReactComponent as inboxfavoriteheart } from "./inbox-favorite-heart-62.svg";
import { ReactComponent as inboxlock } from "./inbox-lock-93.svg";
import { ReactComponent as inboxtray } from "./inbox-tray-1-30.svg";
import { ReactComponent as inboxtray1 } from "./inbox-tray-2-41.svg";
import { ReactComponent as incognitomode } from "./incognito-mode-57.svg";
import { ReactComponent as incomingcall } from "./incoming-call-64.svg";
import { ReactComponent as industryinnovationandinfrastructure } from "./industry-innovation-and-infrastructure-31.svg";
import { ReactComponent as informationcircle } from "./information-circle-18.svg";
import { ReactComponent as informationdesk } from "./information-desk-12.svg";
import { ReactComponent as informationdeskcustomer } from "./information-desk-customer-74.svg";
import { ReactComponent as inputbox } from "./input-box-10.svg";
import { ReactComponent as insertcloudvideo } from "./insert-cloud-video-35.svg";
import { ReactComponent as insertside } from "./insert-side-98.svg";
import { ReactComponent as inserttopleft } from "./insert-top-left-58.svg";
import { ReactComponent as inserttopright } from "./insert-top-right-81.svg";
import { ReactComponent as instagram } from "./instagram-97.svg";
import { ReactComponent as insurancehand } from "./insurance-hand-95.svg";
import { ReactComponent as investmentselection } from "./investment-selection-33.svg";
import { ReactComponent as invisible } from "./invisible-1-12.svg";
import { ReactComponent as invisible1 } from "./invisible-2-69.svg";
import { ReactComponent as iron } from "./iron-31.svg";
import { ReactComponent as islam } from "./islam-79.svg";
import { ReactComponent as jumpobject } from "./jump-object-86.svg";
import { ReactComponent as justicehammer } from "./justice-hammer-74.svg";
import { ReactComponent as justicescale } from "./justice-scale-1-80.svg";
import { ReactComponent as justicescale1 } from "./justice-scale-2-43.svg";
import { ReactComponent as key } from "./key-15.svg";
import { ReactComponent as keyboard } from "./keyboard-49.svg";
import { ReactComponent as keyboardvirtual } from "./keyboard-virtual-60.svg";
import { ReactComponent as keyboardUwireless } from "./keyboard-wireless-2-79.svg";
import { ReactComponent as keyholelockcircle } from "./keyhole-lock-circle-75.svg";
import { ReactComponent as ladder } from "./ladder-66.svg";
import { ReactComponent as landscape } from "./landscape-2-37.svg";
import { ReactComponent as landscapesetting } from "./landscape-setting-20.svg";
import { ReactComponent as laptopcamera } from "./laptop-camera-81.svg";
import { ReactComponent as laptopcharging } from "./laptop-charging-46.svg";
import { ReactComponent as lassotool } from "./lasso-tool-64.svg";
import { ReactComponent as layers } from "./layers-1-74.svg";
import { ReactComponent as layers1 } from "./layers-2-73.svg";
import { ReactComponent as layoutUwindow } from "./layout-window-1-79.svg";
import { ReactComponent as layoutUwindow2 } from "./layout-window-2-31.svg";
import { ReactComponent as layoutUwindow3 } from "./layout-window-8-66.svg";
import { ReactComponent as layoutUwindow1 } from "./layout-window-11-5.svg";
import { ReactComponent as leaf } from "./leaf-58.svg";
import { ReactComponent as lemonfruitseasoning } from "./lemon-fruit-seasoning-36.svg";
import { ReactComponent as lift } from "./lift-23.svg";
import { ReactComponent as liftdisability } from "./lift-disability-69.svg";
import { ReactComponent as lightbulb } from "./lightbulb-39.svg";
import { ReactComponent as like } from "./like-1-50.svg";
import { ReactComponent as linkchain } from "./link-chain-48.svg";
import { ReactComponent as linkedin } from "./linkedin-39.svg";
import { ReactComponent as lipstick } from "./lipstick-5.svg";
import { ReactComponent as livevideo } from "./live-video-62.svg";
import { ReactComponent as localstoragefolder } from "./local-storage-folder-25.svg";
import { ReactComponent as locationcompass } from "./location-compass-1-32.svg";
import { ReactComponent as locationpin } from "./location-pin-3-90.svg";
import { ReactComponent as locationpindisabled } from "./location-pin-disabled-59.svg";
import { ReactComponent as locationtarget } from "./location-target-1-86.svg";
import { ReactComponent as lockrotation } from "./lock-rotation-89.svg";
import { ReactComponent as log } from "./log-61.svg";
import { ReactComponent as login } from "./login-1-19.svg";
import { ReactComponent as logout } from "./logout-1-25.svg";
import { ReactComponent as loop } from "./loop-1-78.svg";
import { ReactComponent as lostandfound } from "./lost-and-found-5.svg";
import { ReactComponent as magicUwand } from "./magic-wand-2-42.svg";
import { ReactComponent as magnifyingglass } from "./magnifying-glass-14.svg";
import { ReactComponent as magnifyingglasscircle } from "./magnifying-glass-circle-38.svg";
import { ReactComponent as mailincoming } from "./mail-incoming-9.svg";
import { ReactComponent as mailsearch } from "./mail-search-76.svg";
import { ReactComponent as mailsendemailmessage } from "./mail-send-email-message-27.svg";
import { ReactComponent as mailsendenvelope } from "./mail-send-envelope-61.svg";
import { ReactComponent as mailsendreplyall } from "./mail-send-reply-all-49.svg";
import { ReactComponent as mail } from "./mail.svg";
import { ReactComponent as makeupbrush } from "./make-up-brush-51.svg";
import { ReactComponent as mansymbol } from "./man-symbol-57.svg";
import { ReactComponent as manualbook } from "./manual-book-67.svg";
import { ReactComponent as mapfold } from "./map-fold-61.svg";
import { ReactComponent as markdowncircleprogramming } from "./markdown-circle-programming-85.svg";
import { ReactComponent as markdowndocumentprogramming } from "./markdown-document-programming-1.svg";
import { ReactComponent as medicalbag } from "./medical-bag.svg";
import { ReactComponent as medicalcrosssignhealthcare } from "./medical-cross-sign-healthcare-65.svg";
import { ReactComponent as medicalcrosssymbol } from "./medical-cross-symbol-52.svg";
import { ReactComponent as medicalfilesreporthistory } from "./medical-files-report-history-5.svg";
import { ReactComponent as medicalribbon } from "./medical-ribbon-1-82.svg";
import { ReactComponent as medicalsearchdiagnosis } from "./medical-search-diagnosis-24.svg";
import { ReactComponent as megaphone } from "./megaphone-2-46.svg";
import { ReactComponent as meta } from "./meta-22.svg";
import { ReactComponent as microscopeobservationsciene } from "./microscope-observation-sciene-7.svg";
import { ReactComponent as microwave } from "./microwave-97.svg";
import { ReactComponent as milkshake } from "./milkshake-88.svg";
import { ReactComponent as minimizeUwindow } from "./minimize-window-2-28.svg";
import { ReactComponent as missedcall } from "./missed-call-56.svg";
import { ReactComponent as mobilephonecamera } from "./mobile-phone-camera-90.svg";
import { ReactComponent as modulepuzzle } from "./module-puzzle-1-78.svg";
import { ReactComponent as modulepuzzle1 } from "./module-puzzle-3-10.svg";
import { ReactComponent as modulethree } from "./module-three-25.svg";
import { ReactComponent as mooncloud } from "./moon-cloud-83.svg";
import { ReactComponent as mouse } from "./mouse-23.svg";
import { ReactComponent as mouseUwireless } from "./mouse-wireless-1-5.svg";
import { ReactComponent as mouseUwireless1 } from "./mouse-wireless-21.svg";
import { ReactComponent as moustache } from "./moustache-30.svg";
import { ReactComponent as mouthlip } from "./mouth-lip-67.svg";
import { ReactComponent as moveleft } from "./move-left-34.svg";
import { ReactComponent as moveright } from "./move-right-92.svg";
import { ReactComponent as multiplefile } from "./multiple-file-2-96.svg";
import { ReactComponent as musicequalizer } from "./music-equalizer-86.svg";
import { ReactComponent as musicfoldersong } from "./music-folder-song-65.svg";
import { ReactComponent as musicnote } from "./music-note-1-79.svg";
import { ReactComponent as musicnote1 } from "./music-note-2-12.svg";
import { ReactComponent as musicnoteoff } from "./music-note-off-1-87.svg";
import { ReactComponent as musicnoteoff1 } from "./music-note-off-2-43.svg";
import { ReactComponent as navigationarrowoff } from "./navigation-arrow-off-89.svg";
import { ReactComponent as navigationarrowon } from "./navigation-arrow-on-38.svg";
import { ReactComponent as necklace } from "./necklace-62.svg";
import { ReactComponent as necktie } from "./necktie-41.svg";
import { ReactComponent as netflix } from "./netflix-90.svg";
import { ReactComponent as network } from "./network-6.svg";
import { ReactComponent as newfile } from "./new-file-3.svg";
import { ReactComponent as newfolder } from "./new-folder-35.svg";
import { ReactComponent as newstickynote } from "./new-sticky-note-87.svg";
import { ReactComponent as newspaper } from "./news-paper-48.svg";
import { ReactComponent as next } from "./next-28.svg";
import { ReactComponent as nintendoswitch } from "./nintendo-switch-20.svg";
import { ReactComponent as nopoverty } from "./no-poverty-43.svg";
import { ReactComponent as notequalsign } from "./not-equal-sign-22.svg";
import { ReactComponent as notificationalarm } from "./notification-alarm-2-17.svg";
import { ReactComponent as notificationapplication } from "./notification-application-1-73.svg";
import { ReactComponent as notificationapplication1 } from "./notification-application-2-2.svg";
import { ReactComponent as notificationmessagealert } from "./notification-message-alert-68.svg";
import { ReactComponent as nurseassistantemergency } from "./nurse-assistant-emergency-31.svg";
import { ReactComponent as nursehat } from "./nurse-hat-26.svg";
import { ReactComponent as octopus } from "./octopus-7.svg";
import { ReactComponent as officebuilding } from "./office-building-1-18.svg";
import { ReactComponent as officeUworker } from "./office-worker-30.svg";
import { ReactComponent as okhand } from "./ok-hand-82.svg";
import { ReactComponent as onefingerdraghorizontal } from "./one-finger-drag-horizontal-27.svg";
import { ReactComponent as onefingerdragvertical } from "./one-finger-drag-vertical-53.svg";
import { ReactComponent as onefingerhold } from "./one-finger-hold-44.svg";
import { ReactComponent as onefingertap } from "./one-finger-tap-1.svg";
import { ReactComponent as onevesusone } from "./one-vesus-one-94.svg";
import { ReactComponent as onlinemedicalcallservice } from "./online-medical-call-service-59.svg";
import { ReactComponent as onlinemedicalservicemonitor } from "./online-medical-service-monitor-14.svg";
import { ReactComponent as onlinemedicalUwebservice } from "./online-medical-web-service-36.svg";
import { ReactComponent as openbook } from "./open-book-85.svg";
import { ReactComponent as openumbrella } from "./open-umbrella-88.svg";
import { ReactComponent as orientationlandscape } from "./orientation-landscape-50.svg";
import { ReactComponent as orientationportrait } from "./orientation-portrait-63.svg";
import { ReactComponent as outgoingcall } from "./outgoing-call-96.svg";
import { ReactComponent as pacman } from "./pacman-3.svg";
import { ReactComponent as padlocksquare } from "./padlock-square-1-63.svg";
import { ReactComponent as pagesetting } from "./page-setting-13.svg";
import { ReactComponent as paintbucket } from "./paint-bucket-26.svg";
import { ReactComponent as paintpalette } from "./paint-palette-43.svg";
import { ReactComponent as paintbrush } from "./paintbrush-1-97.svg";
import { ReactComponent as paintbrush1 } from "./paintbrush-2-93.svg";
import { ReactComponent as paperclip } from "./paperclip-1-20.svg";
import { ReactComponent as parachutedrop } from "./parachute-drop-98.svg";
import { ReactComponent as paragraph } from "./paragraph-71.svg";
import { ReactComponent as parkingsign } from "./parking-sign-78.svg";
import { ReactComponent as parliament } from "./parliament-75.svg";
import { ReactComponent as partypopper } from "./party-popper-34.svg";
import { ReactComponent as passport } from "./passport-9.svg";
import { ReactComponent as pathfinderdivide } from "./pathfinder-divide-16.svg";
import { ReactComponent as pathfinderexclude } from "./pathfinder-exclude-80.svg";
import { ReactComponent as pathfinderintersect } from "./pathfinder-intersect-32.svg";
import { ReactComponent as pathfindermerge } from "./pathfinder-merge-41.svg";
import { ReactComponent as pathfinderminusfront } from "./pathfinder-minus-front-1-90.svg";
import { ReactComponent as pathfindertrim } from "./pathfinder-trim-79.svg";
import { ReactComponent as pathfinderunion } from "./pathfinder-union-13.svg";
import { ReactComponent as payment } from "./payment-10-92.svg";
import { ReactComponent as paymentcashout } from "./payment-cash-out-3-8.svg";
import { ReactComponent as paypal } from "./paypal-67.svg";
import { ReactComponent as peacehand } from "./peace-hand-38.svg";
import { ReactComponent as peacesymbol } from "./peace-symbol-47.svg";
import { ReactComponent as pen } from "./pen-3-74.svg";
import { ReactComponent as pendraw } from "./pen-draw-56.svg";
import { ReactComponent as pentool } from "./pen-tool-33.svg";
import { ReactComponent as pencil } from "./pencil-68.svg";
import { ReactComponent as pentagon } from "./pentagon-63.svg";
import { ReactComponent as petpaw } from "./pet-paw-67.svg";
import { ReactComponent as petridishlabequipment } from "./petri-dish-lab-equipment-11.svg";
import { ReactComponent as petsallowed } from "./pets-allowed-40.svg";
import { ReactComponent as pharmacy } from "./pharmacy-92.svg";
import { ReactComponent as phone } from "./phone-47.svg";
import { ReactComponent as phonemobilephone } from "./phone-mobile-phone-11.svg";
import { ReactComponent as phoneqr } from "./phone-qr-18.svg";
import { ReactComponent as phoneringing } from "./phone-ringing-1-97.svg";
import { ReactComponent as phoneringing1 } from "./phone-ringing-2-28.svg";
import { ReactComponent as tel } from "./phone.svg";
import { ReactComponent as pisymbolcircle } from "./pi-symbol-circle-14.svg";
import { ReactComponent as picturesfoldermemories } from "./pictures-folder-memories-6.svg";
import { ReactComponent as piechart } from "./pie-chart-25.svg";
import { ReactComponent as piggybank } from "./piggy-bank-9.svg";
import { ReactComponent as planet } from "./planet-90.svg";
import { ReactComponent as playlist } from "./play-list-4-25.svg";
import { ReactComponent as playlist1 } from "./play-list-5-21.svg";
import { ReactComponent as playlist2 } from "./play-list-8-38.svg";
import { ReactComponent as playlist3 } from "./play-list-9-35.svg";
import { ReactComponent as playlistfolder } from "./play-list-folder-52.svg";
import { ReactComponent as playstation } from "./play-station-48.svg";
import { ReactComponent as playstore } from "./play-store-59.svg";
import { ReactComponent as podium } from "./podium-89.svg";
import { ReactComponent as polaroidfour } from "./polaroid-four-45.svg";
import { ReactComponent as politicscompaign } from "./politics-compaign-8.svg";
import { ReactComponent as politicsspeech } from "./politics-speech-62.svg";
import { ReactComponent as politicsvote } from "./politics-vote-2-14.svg";
import { ReactComponent as polkadotcircle } from "./polka-dot-circle-54.svg";
import { ReactComponent as polygon } from "./polygon-99.svg";
import { ReactComponent as poolladder } from "./pool-ladder-6.svg";
import { ReactComponent as popcorn } from "./popcorn-31.svg";
import { ReactComponent as porkmeat } from "./pork-meat-17.svg";
import { ReactComponent as pottedflowertulip } from "./potted-flower-tulip-75.svg";
import { ReactComponent as prayinghand } from "./praying-hand-4.svg";
import { ReactComponent as prescriptionpillsdrugshealthcare } from "./prescription-pills-drugs-healthcare-25.svg";
import { ReactComponent as printer } from "./printer-7.svg";
import { ReactComponent as productionbelt } from "./production-belt-40.svg";
import { ReactComponent as projectorboard } from "./projector-board-97.svg";
import { ReactComponent as pyramidshape } from "./pyramid-shape-71.svg";
import { ReactComponent as qrcode } from "./qr-code-72.svg";
import { ReactComponent as qualityeducation } from "./quality-education-73.svg";
import { ReactComponent as quotation } from "./quotation-2-58.svg";
import { ReactComponent as radio } from "./radio-68.svg";
import { ReactComponent as radioactive } from "./radioactive-2-21.svg";
import { ReactComponent as raincloud } from "./rain-cloud-43.svg";
import { ReactComponent as rainbow } from "./rainbow-70.svg";
import { ReactComponent as receipt } from "./receipt-42.svg";
import { ReactComponent as receiptadd } from "./receipt-add-50.svg";
import { ReactComponent as receiptcheck } from "./receipt-check-95.svg";
import { ReactComponent as receiptsubtract } from "./receipt-subtract-98.svg";
import { ReactComponent as recordingtapebubblecircle } from "./recording-tape-bubble-circle-91.svg";
import { ReactComponent as recordingtapebubblesquare } from "./recording-tape-bubble-square-55.svg";
import { ReactComponent as recycle } from "./recycle-1-23.svg";
import { ReactComponent as recyclebin } from "./recycle-bin-2-9.svg";
import { ReactComponent as reducedinequalities } from "./reduced-inequalities-3.svg";
import { ReactComponent as refrigerator } from "./refrigerator-56.svg";
import { ReactComponent as return1 } from "./return-2-3.svg";
import { ReactComponent as ringingbellnotification } from "./ringing-bell-notification-87.svg";
import { ReactComponent as rockandrollhand } from "./rock-and-roll-hand-50.svg";
import { ReactComponent as rockslide } from "./rock-slide-92.svg";
import { ReactComponent as rose } from "./rose-66.svg";
import { ReactComponent as rotateangle } from "./rotate-angle-45-54.svg";
import { ReactComponent as roundcap } from "./round-cap-76.svg";
import { ReactComponent as rsssquare } from "./rss-square-95.svg";
import { ReactComponent as rsssymbol } from "./rss-symbol-8.svg";
import { ReactComponent as sadface } from "./sad-face-40.svg";
import { ReactComponent as safevault } from "./safe-vault-2.svg";
import { ReactComponent as sailship } from "./sail-ship-97.svg";
import { ReactComponent as satellitedish } from "./satellite-dish-62.svg";
import { ReactComponent as scanner } from "./scanner-3-94.svg";
import { ReactComponent as scanner1 } from "./scanner-96.svg";
import { ReactComponent as scannerbarcode } from "./scanner-bar-code-68.svg";
import { ReactComponent as schoolbusside } from "./school-bus-side-11.svg";
import { ReactComponent as screen } from "./screen-1-11.svg";
import { ReactComponent as screen1 } from "./screen-2-77.svg";
import { ReactComponent as screenbroadcast } from "./screen-broadcast-23.svg";
import { ReactComponent as screencurve } from "./screen-curve-20.svg";
import { ReactComponent as screensavermonitorUwallpaper } from "./screensaver-monitor-wallpaper-82.svg";
import { ReactComponent as script } from "./script-2-29.svg";
import { ReactComponent as searchdollar } from "./search-dollar-33.svg";
import { ReactComponent as searchvisual } from "./search-visual-16.svg";
import { ReactComponent as selectcirclearea } from "./select-circle-area-1-51.svg";
import { ReactComponent as sendemail } from "./send-email-21.svg";
import { ReactComponent as servingdome } from "./serving-dome-84.svg";
import { ReactComponent as sharelink } from "./share-link-88.svg";
import { ReactComponent as shelf } from "./shelf-63.svg";
import { ReactComponent as shell } from "./shell-21.svg";
import { ReactComponent as shield } from "./shield-1-23.svg";
import { ReactComponent as shield1 } from "./shield-2-32.svg";
import { ReactComponent as shieldcheck } from "./shield-check-84.svg";
import { ReactComponent as shieldcross } from "./shield-cross-70.svg";
import { ReactComponent as shift } from "./shift-45.svg";
import { ReactComponent as shipmentadd } from "./shipment-add-53.svg";
import { ReactComponent as shipmentcheck } from "./shipment-check-41.svg";
import { ReactComponent as shipmentdownload } from "./shipment-download-36.svg";
import { ReactComponent as shipmentremove } from "./shipment-remove-74.svg";
import { ReactComponent as shipmentupload } from "./shipment-upload-27.svg";
import { ReactComponent as shippingbox } from "./shipping-box-1-81.svg";
import { ReactComponent as shippingtruck } from "./shipping-truck-40.svg";
import { ReactComponent as shoppingbaghandbag } from "./shopping-bag-hand-bag-2-11.svg";
import { ReactComponent as shoppingbasket } from "./shopping-basket-1-44.svg";
import { ReactComponent as shoppingbasket1 } from "./shopping-basket-2-76.svg";
import { ReactComponent as shoppingcart } from "./shopping-cart-1-73.svg";
import { ReactComponent as shoppingcart1 } from "./shopping-cart-2-32.svg";
import { ReactComponent as shoppingcart2 } from "./shopping-cart-3-35.svg";
import { ReactComponent as shoppingcartadd } from "./shopping-cart-add-87.svg";
import { ReactComponent as shoppingcartcheck } from "./shopping-cart-check-27.svg";
import { ReactComponent as shoppingcartsubtract } from "./shopping-cart-subtract-81.svg";
import { ReactComponent as shovelrake } from "./shovel-rake-16.svg";
import { ReactComponent as shredder } from "./shredder-48.svg";
import { ReactComponent as shrimp } from "./shrimp-5.svg";
import { ReactComponent as shrinkhorizontal } from "./shrink-horizontal-1-18.svg";
import { ReactComponent as shuffle } from "./shuffle-60.svg";
import { ReactComponent as sigma } from "./sigma-94.svg";
import { ReactComponent as signat } from "./sign-at-42.svg";
import { ReactComponent as signcrosssquare } from "./sign-cross-square-55.svg";
import { ReactComponent as signhashtag } from "./sign-hashtag-68.svg";
import { ReactComponent as signage } from "./signage-3-70.svg";
import { ReactComponent as signage1 } from "./signage-4-20.svg";
import { ReactComponent as signalfull } from "./signal-full-48.svg";
import { ReactComponent as signalloading } from "./signal-loading-85.svg";
import { ReactComponent as signallow } from "./signal-low-83.svg";
import { ReactComponent as signalmedium } from "./signal-medium-7.svg";
import { ReactComponent as signalnone } from "./signal-none-24.svg";
import { ReactComponent as skull } from "./skull-1-86.svg";
import { ReactComponent as slack } from "./slack-61.svg";
import { ReactComponent as sleep } from "./sleep-44.svg";
import { ReactComponent as smileyangry } from "./smiley-angry-94.svg";
import { ReactComponent as smileycool } from "./smiley-cool-71.svg";
import { ReactComponent as smileycrying } from "./smiley-crying-1-84.svg";
import { ReactComponent as smileycute } from "./smiley-cute-29.svg";
import { ReactComponent as smileydrool } from "./smiley-drool-53.svg";
import { ReactComponent as smileyemojikissnervous } from "./smiley-emoji-kiss-nervous-51.svg";
import { ReactComponent as smileyemojiterrified } from "./smiley-emoji-terrified-83.svg";
import { ReactComponent as smileygrumpy } from "./smiley-grumpy-7.svg";
import { ReactComponent as smileyhappy } from "./smiley-happy-58.svg";
import { ReactComponent as smileyinlove } from "./smiley-in-love-98.svg";
import { ReactComponent as smileykiss } from "./smiley-kiss-26.svg";
import { ReactComponent as smileylaughing } from "./smiley-laughing-3-41.svg";
import { ReactComponent as smileymask } from "./smiley-mask-4.svg";
import { ReactComponent as smileynauseas } from "./smiley-nauseas-15.svg";
import { ReactComponent as smileysmirk } from "./smiley-smirk-91.svg";
import { ReactComponent as smileysparks } from "./smiley-sparks-18.svg";
import { ReactComponent as smileysurprised } from "./smiley-surprised-21.svg";
import { ReactComponent as smileythrowup } from "./smiley-throw-up-82.svg";
import { ReactComponent as smileyveryshocked } from "./smiley-very-shocked-25.svg";
import { ReactComponent as smokedetector } from "./smoke-detector-35.svg";
import { ReactComponent as smokingarea } from "./smoking-area-49.svg";
import { ReactComponent as snorkle } from "./snorkle-80.svg";
import { ReactComponent as snowflake } from "./snow-flake-93.svg";
import { ReactComponent as songrecommendation } from "./song-recommendation-10.svg";
import { ReactComponent as sortdescending } from "./sort-descending-36.svg";
import { ReactComponent as soshelpemergencysign } from "./sos-help-emergency-sign-22.svg";
import { ReactComponent as spadessymbol } from "./spades-symbol-78.svg";
import { ReactComponent as speaker } from "./speaker-1-24.svg";
import { ReactComponent as speaker1 } from "./speaker-2-14.svg";
import { ReactComponent as spiralshape } from "./spiral-shape-61.svg";
import { ReactComponent as splitvertical } from "./split-vertical-15.svg";
import { ReactComponent as spotify } from "./spotify-30.svg";
import { ReactComponent as spraypaint } from "./spray-paint-7.svg";
import { ReactComponent as sprout } from "./sprout-96.svg";
import { ReactComponent as squarebracketscircle } from "./square-brackets-circle-91.svg";
import { ReactComponent as squarecap } from "./square-cap-65.svg";
import { ReactComponent as squareclock } from "./square-clock-20.svg";
import { ReactComponent as squarerootxcircle } from "./square-root-x-circle-45.svg";
import { ReactComponent as stageClockHand } from "./stage-clock-hand.svg";
import { ReactComponent as star } from "./star-1-66.svg";
import { ReactComponent as star1 } from "./star-2-85.svg";
import { ReactComponent as starbadge } from "./star-badge-1.svg";
import { ReactComponent as startup } from "./startup-56.svg";
import { ReactComponent as steeringUwheel } from "./steering-wheel-54.svg";
import { ReactComponent as stepsnumber } from "./steps-number-98.svg";
import { ReactComponent as stethoscope } from "./stethoscope-70.svg";
import { ReactComponent as stock } from "./stock-61.svg";
import { ReactComponent as store } from "./store-1-13.svg";
import { ReactComponent as store1 } from "./store-2-91.svg";
import { ReactComponent as storecomputer } from "./store-computer-39.svg";
import { ReactComponent as straightcap } from "./straight-cap-5.svg";
import { ReactComponent as straightface } from "./straight-face-63.svg";
import { ReactComponent as strategytasks } from "./strategy-tasks-20.svg";
import { ReactComponent as strawberry } from "./strawberry-99.svg";
import { ReactComponent as stream } from "./stream-39.svg";
import { ReactComponent as streamLineFileCheck } from "./streamline-core-file-check.svg";
import { ReactComponent as streamlineCoreLineFileText } from "./streamline-core-line-file-text.svg";
import { ReactComponent as streetroad } from "./street-road-60.svg";
import { ReactComponent as streetsign } from "./street-sign-76.svg";
import { ReactComponent as subscriptioncashflow } from "./subscription-cashflow-65.svg";
import { ReactComponent as subtract } from "./subtract-1-64.svg";
import { ReactComponent as subtractcircle } from "./subtract-circle-80.svg";
import { ReactComponent as subtractsquare } from "./subtract-square-35.svg";
import { ReactComponent as suncloud } from "./sun-cloud-41.svg";
import { ReactComponent as synchronizedisable } from "./synchronize-disable-49.svg";
import { ReactComponent as synchronizeUwarning } from "./synchronize-warning-52.svg";
import { ReactComponent as syringe } from "./syringe-39.svg";
import { ReactComponent as tablelamp } from "./table-lamp-1-72.svg";
import { ReactComponent as tabletcapsule } from "./tablet-capsule-19.svg";
import { ReactComponent as tag1 } from "./tag-4.svg";
import { ReactComponent as tag } from "./tag-22.svg";
import { ReactComponent as takeoff } from "./take-off-77.svg";
import { ReactComponent as tallhat } from "./tall-hat-14.svg";
import { ReactComponent as tapecassetterecord } from "./tape-cassette-record-81.svg";
import { ReactComponent as target } from "./target-3-77.svg";
import { ReactComponent as target1 } from "./target-78.svg";
import { ReactComponent as tasklist } from "./task-list-85.svg";
import { ReactComponent as teacup } from "./tea-cup-16.svg";
import { ReactComponent as telegram } from "./telegram-74.svg";
import { ReactComponent as telescope } from "./telescope-13.svg";
import { ReactComponent as testtube } from "./test-tube-32.svg";
import { ReactComponent as textflowrows } from "./text-flow-rows-82.svg";
import { ReactComponent as textsquare } from "./text-square-34.svg";
import { ReactComponent as textstyle } from "./text-style-73.svg";
import { ReactComponent as thermometer } from "./thermometer-26.svg";
import { ReactComponent as threatbrowser } from "./threat-browser-1-51.svg";
import { ReactComponent as threatdocument } from "./threat-document-31.svg";
import { ReactComponent as threatfolder } from "./threat-folder-52.svg";
import { ReactComponent as ticket } from "./ticket-1-34.svg";
import { ReactComponent as tickets } from "./tickets-37.svg";
import { ReactComponent as tidalUwave } from "./tidal-wave-12.svg";
import { ReactComponent as tiktok } from "./tiktok-38.svg";
import { ReactComponent as tinder } from "./tinder-56.svg";
import { ReactComponent as toast } from "./toast-66.svg";
import { ReactComponent as toiletman } from "./toilet-man-27.svg";
import { ReactComponent as toiletsignmanUwoman } from "./toilet-sign-man-woman-2-81.svg";
import { ReactComponent as toiletUwomen } from "./toilet-women-37.svg";
import { ReactComponent as tooth } from "./tooth-12.svg";
import { ReactComponent as trafficcone } from "./traffic-cone-88.svg";
import { ReactComponent as transfermotorcycle } from "./transfer-motorcycle-64.svg";
import { ReactComponent as transfervan } from "./transfer-van-67.svg";
import { ReactComponent as tree } from "./tree-2-35.svg";
import { ReactComponent as tree1 } from "./tree-3-80.svg";
import { ReactComponent as trendingcontent } from "./trending-content-28.svg";
import { ReactComponent as triangleflag } from "./triangle-flag-17.svg";
import { ReactComponent as trophy } from "./trophy-37.svg";
import { ReactComponent as twitter } from "./twitter-70.svg";
import { ReactComponent as twofingerdraghotizontal } from "./two-finger-drag-hotizontal-55.svg";
import { ReactComponent as twofingertap } from "./two-finger-tap-67.svg";
import { ReactComponent as underlinetext } from "./underline-text-1-69.svg";
import { ReactComponent as uploadbox } from "./upload-box-1-24.svg";
import { ReactComponent as uploadcircle } from "./upload-circle-59.svg";
import { ReactComponent as uploadcomputer } from "./upload-computer-42.svg";
import { ReactComponent as uploadfile } from "./upload-file-3.svg";
import { ReactComponent as usbdrive } from "./usb-drive-60.svg";
import { ReactComponent as useraddplus } from "./user-add-plus-10.svg";
import { ReactComponent as usercheckvalidate } from "./user-check-validate-95.svg";
import { ReactComponent as usercirclesingle } from "./user-circle-single-77.svg";
import { ReactComponent as useridentifiercard } from "./user-identifier-card-19.svg";
import { ReactComponent as usermultiplecircle } from "./user-multiple-circle-75.svg";
import { ReactComponent as usermultiplegroup } from "./user-multiple-group-30.svg";
import { ReactComponent as userprofilefocus } from "./user-profile-focus-46.svg";
import { ReactComponent as userprotection } from "./user-protection-2-29.svg";
import { ReactComponent as userremovesubtract } from "./user-remove-subtract-11.svg";
import { ReactComponent as usersingleneutralmale } from "./user-single-neutral-male-25.svg";
import { ReactComponent as usersynconlineinperson } from "./user-sync-online-in-person-98.svg";
import { ReactComponent as verticalslidersquare } from "./vertical-slider-square-40.svg";
import { ReactComponent as videoswapcamera } from "./video-swap-camera-31.svg";
import { ReactComponent as virtualreality } from "./virtual-reality-72.svg";
import { ReactComponent as virusantivirus } from "./virus-antivirus-79.svg";
import { ReactComponent as visible } from "./visible-48.svg";
import { ReactComponent as voicemail } from "./voice-mail-30.svg";
import { ReactComponent as voicemailoff } from "./voice-mail-off-75.svg";
import { ReactComponent as voicescan } from "./voice-scan-2-53.svg";
import { ReactComponent as volcano } from "./volcano-77.svg";
import { ReactComponent as volumedown } from "./volume-down-85.svg";
import { ReactComponent as volumelevelhigh } from "./volume-level-high-98.svg";
import { ReactComponent as volumelevellow } from "./volume-level-low-64.svg";
import { ReactComponent as volumeleveloff } from "./volume-level-off-80.svg";
import { ReactComponent as volumemute } from "./volume-mute-83.svg";
import { ReactComponent as volumeoff } from "./volume-off-67.svg";
import { ReactComponent as vpnconnection } from "./vpn-connection-42.svg";
import { ReactComponent as vrheadset } from "./vr-headset-1-65.svg";
import { ReactComponent as vrheadset1 } from "./vr-headset-2-76.svg";
import { ReactComponent as Uwaitingappointmentscalendar } from "./waiting-appointments-calendar-96.svg";
import { ReactComponent as Uwallet } from "./wallet-58.svg";
import { ReactComponent as Uwalletpurse } from "./wallet-purse-91.svg";
import { ReactComponent as Uwaningcresentmoon } from "./waning-cresent-moon-92.svg";
import { ReactComponent as Uwarehouse } from "./warehouse-1-87.svg";
import { ReactComponent as Uwarningoctagon } from "./warning-octagon-27.svg";
import { ReactComponent as Uwarningtriangle } from "./warning-triangle-57.svg";
import { ReactComponent as Uwatch } from "./watch-1-26.svg";
import { ReactComponent as Uwatch1 } from "./watch-2-58.svg";
import { ReactComponent as Uwatchcirclecharging } from "./watch-circle-charging-3.svg";
import { ReactComponent as Uwatchcircleheartbeatmonitor } from "./watch-circle-heartbeat-monitor-1-16.svg";
import { ReactComponent as Uwatchcircleheartbeatmonitor1 } from "./watch-circle-heartbeat-monitor-2-37.svg";
import { ReactComponent as Uwatchcirclemenu } from "./watch-circle-menu-50.svg";
import { ReactComponent as Uwatchcircletime } from "./watch-circle-time-93.svg";
import { ReactComponent as Uwaterglass } from "./water-glass-41.svg";
import { ReactComponent as Uwavesignal } from "./wave-signal-83.svg";
import { ReactComponent as Uwavesignalsquare } from "./wave-signal-square.svg";
import { ReactComponent as Uwavinghand } from "./waving-hand-17.svg";
import { ReactComponent as Uweb } from "./web-20.svg";
import { ReactComponent as Uwebcam } from "./webcam-87.svg";
import { ReactComponent as Uwebcamvideo } from "./webcam-video-77.svg";
import { ReactComponent as Uwebcamvideocircle } from "./webcam-video-circle-46.svg";
import { ReactComponent as Uwebcamvideooff } from "./webcam-video-off-32.svg";
import { ReactComponent as Uwhatsapp } from "./whatsapp-76.svg";
import { ReactComponent as Uwheelchair } from "./wheelchair-1-99.svg";
import { ReactComponent as Uwheelchair1 } from "./wheelchair-8.svg";
import { ReactComponent as Uwidget } from "./widget-79.svg";
import { ReactComponent as Uwifi } from "./wifi-9.svg";
import { ReactComponent as Uwifiantenna } from "./wifi-antenna-4.svg";
import { ReactComponent as Uwifidisabled } from "./wifi-disabled-55.svg";
import { ReactComponent as Uwifihorizontal } from "./wifi-horizontal-21.svg";
import { ReactComponent as Uwifirouter } from "./wifi-router-33.svg";
import { ReactComponent as Uwindflow } from "./wind-flow-1-78.svg";
import { ReactComponent as Uwindflow1 } from "./wind-flow-2-2.svg";
import { ReactComponent as Uwindmill } from "./windmill-69.svg";
import { ReactComponent as Uwindows } from "./windows-84.svg";
import { ReactComponent as Uwine } from "./wine-6.svg";
import { ReactComponent as Uwomansymbol } from "./woman-symbol-84.svg";
import { ReactComponent as Uworkspacedesk } from "./workspace-desk.svg";
import { ReactComponent as Uwrench } from "./wrench-47.svg";
import { ReactComponent as xbox } from "./xbox-90.svg";
import { ReactComponent as xrpcircle } from "./xrp-circle-9.svg";
import { ReactComponent as yinyangsymbol } from "./yin-yang-symbol-55.svg";
import { ReactComponent as yuan } from "./yuan-62.svg";
import { ReactComponent as yuancircle } from "./yuan-circle-88.svg";
import { ReactComponent as zerohunger } from "./zero-hunger-86.svg";
import { ReactComponent as zodiac } from "./zodiac-1-59.svg";
import { ReactComponent as zodiac4 } from "./zodiac-2-10.svg";
import { ReactComponent as zodiac5 } from "./zodiac-3-60.svg";
import { ReactComponent as zodiac6 } from "./zodiac-4-10.svg";
import { ReactComponent as zodiac7 } from "./zodiac-5-44.svg";
import { ReactComponent as zodiac8 } from "./zodiac-6-76.svg";
import { ReactComponent as zodiac9 } from "./zodiac-7-82.svg";
import { ReactComponent as zodiac10 } from "./zodiac-8-84.svg";
import { ReactComponent as zodiac11 } from "./zodiac-9-54.svg";
import { ReactComponent as zodiac1 } from "./zodiac-10-9.svg";
import { ReactComponent as zodiac2 } from "./zodiac-11-48.svg";
import { ReactComponent as zodiac3 } from "./zodiac-12-89.svg";

export const icons = {
  checkCircle,
  alertCircle,
  chevronUp,
  chevronLeft,
  chevronRight,
  chevronDown,
  add,
  addbellnotification,
  addcircle,
  addlayer,
  addsquare,
  adobe,
  affordableandcleanenergy,
  aichipspark,
  aicloudspark,
  aieditspark,
  aiemailgeneratorspark,
  aigamingspark,
  aigeneratelandscapeimagespark,
  aigeneratemusicspark,
  aigenerateportraitimagespark,
  aigeneratevariationspark,
  ainavigationspark,
  ainetworkspark,
  aipromptspark,
  airedospark,
  aisciencespark,
  aisettingsspark,
  aitechnologyspark,
  aiupscalespark,
  aivehiclespark,
  airplane,
  airplanedisabled,
  airplaneenabled,
  airportplane,
  airportplanetransit,
  airportsecurity,
  alarmclock,
  alien,
  alignback,
  aligncenter,
  alignfront,
  alignleft,
  alignright,
  alt,
  amazon,
  ambulance,
  ampersand,
  anchor,
  android,
  announcementmegaphone,
  appstore,
  apple,
  applicationadd,
  archivebox,
  arrowbendleftdown,
  arrowbendrightdown,
  arrowcrossoverdown,
  arrowcrossoverleft,
  arrowcrossoverright,
  arrowcrossoverup,
  arrowcursor,
  arrowcursor1,
  arrowcurvyupdown,
  arrowcurvyupdown1,
  arrowdown,
  arrowdowndashedsquare,
  arrowexpand,
  arrowinfiniteloop,
  arrowmove,
  arrowreloadhorizontal,
  arrowreloadhorizontal1,
  arrowreloadvertical,
  arrowreloadvertical1,
  arrowroadmap,
  arrowroundleft,
  arrowroundright,
  arrowshrink,
  arrowshrinkdiagonal,
  arrowshrinkdiagonal1,
  arrowtransferdiagonal,
  arrowtransferdiagonal1,
  arrowtransferdiagonal2,
  arrowup,
  arrowupdashedsquare,
  artificialintelligencespark,
  ascendingnumberorder,
  asterisk,
  attribution,
  autoflash,
  backcamera,
  backpack,
  bacteriaviruscellsbiology,
  bag,
  bagdollar,
  bagpound,
  bagrupee,
  bagsuitcase,
  bagsuitcase1,
  bagyen,
  baggage,
  ball,
  balloon,
  bandage,
  bank,
  batteryalert,
  batterycharging,
  batteryempty,
  batteryempty1,
  batteryfull,
  batterylow,
  batterymedium,
  beach,
  beanie,
  beermug,
  beerpitch,
  bicyclebike,
  bill,
  bill1,
  bill2,
  billcashless,
  binancecircle,
  bitcoin,
  blankcalendar,
  blanknotepad,
  blockbellnotification,
  bloodbagdonation,
  blooddonatedrop,
  blooddropdonation,
  bluetooth,
  bluetoothdisabled,
  bluetoothsearching,
  bomb,
  bone,
  bookreading,
  bookmark,
  bow,
  bowtie,
  boxsign,
  bracescircle,
  bracket,
  brailleblind,
  brain,
  braincognitive,
  briefcasedollar,
  brightness,
  brightness1,
  brightness2,
  brokenlink,
  browseradd,
  browserblock,
  browserbuild,
  browsercheck,
  browserdelete,
  browserhash,
  browserlock,
  browsermultipleUwindow,
  browserremove,
  browserUwebsite,
  browserUwifi,
  bug,
  bugantivirusdebugging,
  bugantivirusshield,
  bugvirusbrowser,
  bugvirusdocument,
  bugvirusfolder,
  building,
  bulletlist,
  burger,
  burritofastfood,
  bus,
  businesscard,
  businesshandshake,
  businessideamoney,
  businessprofessionhomeoffice,
  businessprogressbar,
  businessusercurriculum,
  buttonfastforward,
  buttonfastforward1,
  buttonnext,
  buttonpause,
  buttonplay,
  buttonpower,
  buttonprevious,
  buttonrecord,
  buttonrewind,
  buttonrewind1,
  buttonstop,
  cakeslice,
  calculator,
  calculator1,
  calendaradd,
  calendaredit,
  calendarjumptodate,
  calendarstar,
  callcentersupportservice,
  callhangup,
  camera,
  cameradisabled,
  cameraloading,
  camerasquare,
  cameravideo,
  campingtent,
  candycane,
  cane,
  cane1,
  capitol,
  carbatterycharging,
  cartaxi,
  cards,
  cat,
  cellularnetworkg,
  cellularnetworkg1,
  cellularnetworklte,
  celsius,
  chair,
  champagnepartyalcohol,
  chatbubbleoval,
  chatbubbleovalnotification,
  chatbubbleovalsmiley,
  chatbubbleovalsmiley1,
  chatbubblesquareblock,
  chatbubblesquarequestion,
  chatbubblesquareUwarning,
  chatbubblesquareUwrite,
  chatbubbletextsquare,
  chatbubbletypingoval,
  chattwobubblesoval,
  check,
  checksquare,
  checkupmedicalreportclipboard,
  cheese,
  cherries,
  chessbishop,
  chessking,
  chessknight,
  chesspawn,
  chickengrilledstream,
  christiancross,
  christiancross1,
  christianity,
  chrome,
  circle,
  circleclock,
  circleflask,
  cityhall,
  classlesson,
  cleanUwaterandsanitation,
  clipboardadd,
  clipboardcheck,
  clipboardremove,
  closet,
  cloud,
  cloudadd,
  cloudblock,
  cloudcheck,
  clouddatatransfer,
  cloudgaming,
  cloudrefresh,
  cloudshare,
  cloudUwarning,
  cloudUwifi,
  clubssymbol,
  cocktail,
  codeanalysis,
  codemonitor,
  codemonitor1,
  coffeebean,
  coffeemug,
  coffeetakeawaycup,
  cog,
  coinshare,
  coinsstack,
  collaborationsidea,
  colorpalette,
  colorpicker,
  colorswatches,
  comet,
  command,
  compassnavigator,
  compositionoval,
  compositionvertical,
  compsitionhorizontal,
  computerchip,
  computerchip1,
  computerpcdesktop,
  coneshape,
  contactphonebook,
  container,
  controller,
  controller1,
  controllerUwireless,
  convertpdf,
  copypaste,
  creativecommons,
  creditcard,
  creditcard1,
  cropselection,
  crown,
  crutch,
  cssthree,
  curlybrackets,
  cursorclick,
  customersupport,
  cut,
  cyborg,
  cyborg1,
  dangerouszonesign,
  darkdislaymode,
  dashboard,
  dashboardcircle,
  database,
  databasecheck,
  databaselock,
  databaserefresh,
  databaseremove,
  databaseserver,
  databaseserver1,
  databasesetting,
  databasesubtractraidstoragecodediskprogrammingdatabasearrayharddiscminus,
  decentUworkandeconomicgrowth,
  definitionsearchbook,
  delete1,
  deletekeyboard,
  descendingnumberorder,
  desktopchat,
  desktopcheck,
  desktopcode,
  desktopdelete,
  desktopdollar,
  desktopemoji,
  desktopfavoritestar,
  desktopgame,
  desktophelp,
  devicedatabaseencryption,
  dhammajak,
  diamond,
  diamondssymbol,
  dice,
  dice1,
  dice2,
  dice3,
  dice4,
  dice5,
  dicesentertainmentgamingdices,
  dictionarylanguagebook,
  disablebellnotification,
  disableheart,
  discord,
  discountpercentbadge,
  discountpercentcircle,
  discountpercentcoupon,
  discountpercentcutout,
  discountpercentfire,
  discussionconverstionreply,
  divisioncircle,
  dna,
  dollarcoin,
  dollarcoin1,
  donut,
  downloadbox,
  downloadcircle,
  downloadcomputer,
  downloadfile,
  dressingtable,
  drone,
  dropbox,
  earhearing,
  earpods,
  earth,
  earthairplane,
  editimagephoto,
  eject,
  electriccord,
  electriccord1,
  emergencyexit,
  emptyclipboard,
  epicgames,
  equalsign,
  erlenmeyerflask,
  esports,
  ethereum,
  ethereumcircle,
  euro,
  expand,
  expandhorizontal,
  expandUwindow,
  eyeoptic,
  facescan,
  facebook,
  factorial,
  fahrenheit,
  fastforwardclock,
  figma,
  fileaddalternate,
  filecode,
  filedeletealternate,
  fileremovealternate,
  filmroll,
  filmslate,
  filter,
  fingerprint,
  fingerprint1,
  firealarm,
  fireextinguishersign,
  fireworksrocket,
  fist,
  fittoheightsquare,
  flash,
  flash1,
  flash2,
  flashoff,
  flipverticalarrow,
  flipverticalcircle,
  flipverticalsquare,
  floppydisk,
  flower,
  flower1,
  flumask,
  focuspoints,
  folderadd,
  foldercheck,
  folderdelete,
  forkknife,
  forkspoon,
  fragile,
  frontcamera,
  galaxy,
  galaxy1,
  gameboy,
  gasstationfuelpetroleum,
  genderequality,
  gifformat,
  gift,
  gift1,
  givegift,
  glasses,
  globallearning,
  gmail,
  gold,
  goodhealthandUwellbeing,
  google,
  googledrive,
  graduationcap,
  gramophone,
  graph,
  grapharrowdecrease,
  grapharrowincrease,
  graphbardecrease,
  graphbarincrease,
  graphdot,
  groupmeetingcall,
  halfstar,
  handcursor,
  handgrab,
  handheld,
  handheldtabletdrawing,
  handheldtabletUwriting,
  hangup,
  hangup1,
  happyface,
  harddisk,
  harddrive,
  headingparagraphstylesheading,
  headingparagraphstylesheading1,
  headingparagraphstylesheading2,
  healthcare,
  hearingdeaf,
  hearingdeaf1,
  heart,
  heartratepulsegraph,
  heartratesearch,
  heartssymbol,
  helpchat,
  helpquestion,
  hexagram,
  hierarchy,
  hierarchy1,
  hierarchy2,
  hierarchy3,
  hierarchy4,
  hierarchy5,
  highspeedtrainfront,
  hinduism,
  home,
  home1,
  horizontalmenucircle,
  hospitalsigncircle,
  hospitalsignsquare,
  hotspring,
  hotelairconditioner,
  hotelbed,
  hotellaundry,
  hotelonestar,
  hotelshowerhead,
  hoteltwostar,
  humiditynone,
  icecream,
  icecream1,
  imageblur,
  imagesaturation,
  inboxblock,
  inboxfavorite,
  inboxfavoriteheart,
  inboxlock,
  inboxtray,
  inboxtray1,
  incognitomode,
  incomingcall,
  industryinnovationandinfrastructure,
  informationcircle,
  informationdesk,
  informationdeskcustomer,
  inputbox,
  insertcloudvideo,
  insertside,
  inserttopleft,
  inserttopright,
  instagram,
  insurancehand,
  investmentselection,
  invisible,
  invisible1,
  iron,
  islam,
  jumpobject,
  justicehammer,
  justicescale,
  justicescale1,
  key,
  keyboard,
  keyboardvirtual,
  keyboardUwireless,
  keyholelockcircle,
  ladder,
  landscape,
  landscapesetting,
  laptopcamera,
  laptopcharging,
  lassotool,
  layers,
  layers1,
  layoutUwindow,
  layoutUwindow1,
  layoutUwindow2,
  layoutUwindow3,
  leaf,
  lemonfruitseasoning,
  lift,
  liftdisability,
  lightbulb,
  like,
  linkchain,
  linkedin,
  lipstick,
  livevideo,
  localstoragefolder,
  locationcompass,
  locationpin,
  locationpindisabled,
  locationtarget,
  lockrotation,
  log,
  login,
  logout,
  loop,
  lostandfound,
  magicUwand,
  magnifyingglass,
  magnifyingglasscircle,
  mailincoming,
  mailsearch,
  mailsendemailmessage,
  mailsendenvelope,
  mailsendreplyall,
  makeupbrush,
  mansymbol,
  manualbook,
  mapfold,
  markdowncircleprogramming,
  markdowndocumentprogramming,
  medicalbag,
  medicalcrosssignhealthcare,
  medicalcrosssymbol,
  medicalfilesreporthistory,
  medicalribbon,
  medicalsearchdiagnosis,
  megaphone,
  meta,
  microscopeobservationsciene,
  microwave,
  milkshake,
  minimizeUwindow,
  missedcall,
  mobilephonecamera,
  modulepuzzle,
  modulepuzzle1,
  modulethree,
  mooncloud,
  mouse,
  mouseUwireless,
  mouseUwireless1,
  moustache,
  mouthlip,
  moveleft,
  moveright,
  multiplefile,
  musicequalizer,
  musicfoldersong,
  musicnote,
  musicnote1,
  musicnoteoff,
  musicnoteoff1,
  navigationarrowoff,
  navigationarrowon,
  necklace,
  necktie,
  netflix,
  network,
  newfile,
  newfolder,
  newstickynote,
  newspaper,
  next,
  nintendoswitch,
  nopoverty,
  notequalsign,
  notificationalarm,
  notificationapplication,
  notificationapplication1,
  notificationmessagealert,
  nurseassistantemergency,
  nursehat,
  octopus,
  officebuilding,
  officeUworker,
  okhand,
  onefingerdraghorizontal,
  onefingerdragvertical,
  onefingerhold,
  onefingertap,
  onevesusone,
  onlinemedicalcallservice,
  onlinemedicalservicemonitor,
  onlinemedicalUwebservice,
  openbook,
  openumbrella,
  orientationlandscape,
  orientationportrait,
  outgoingcall,
  pacman,
  padlocksquare,
  pagesetting,
  paintbucket,
  paintpalette,
  paintbrush,
  paintbrush1,
  paperclip,
  parachutedrop,
  paragraph,
  parkingsign,
  parliament,
  partypopper,
  passport,
  pathfinderdivide,
  pathfinderexclude,
  pathfinderintersect,
  pathfindermerge,
  pathfinderminusfront,
  pathfindertrim,
  pathfinderunion,
  payment,
  paymentcashout,
  paypal,
  peacehand,
  peacesymbol,
  pen,
  pendraw,
  pentool,
  pencil,
  pentagon,
  petpaw,
  petridishlabequipment,
  petsallowed,
  pharmacy,
  phone,
  phonemobilephone,
  phoneqr,
  phoneringing,
  phoneringing1,
  pisymbolcircle,
  picturesfoldermemories,
  piechart,
  piggybank,
  planet,
  playlist,
  playlist1,
  playlist2,
  playlist3,
  playlistfolder,
  playstation,
  playstore,
  podium,
  polaroidfour,
  politicscompaign,
  politicsspeech,
  politicsvote,
  polkadotcircle,
  polygon,
  poolladder,
  popcorn,
  porkmeat,
  pottedflowertulip,
  prayinghand,
  prescriptionpillsdrugshealthcare,
  printer,
  productionbelt,
  projectorboard,
  pyramidshape,
  qrcode,
  qualityeducation,
  quotation,
  radio,
  radioactive,
  raincloud,
  rainbow,
  receipt,
  receiptadd,
  receiptcheck,
  receiptsubtract,
  recordingtapebubblecircle,
  recordingtapebubblesquare,
  recycle,
  recyclebin,
  reducedinequalities,
  refrigerator,
  return1,
  ringingbellnotification,
  rockandrollhand,
  rockslide,
  rose,
  rotateangle,
  roundcap,
  rsssquare,
  rsssymbol,
  sadface,
  safevault,
  sailship,
  satellitedish,
  scanner,
  scanner1,
  scannerbarcode,
  schoolbusside,
  screen,
  screen1,
  screenbroadcast,
  screencurve,
  screensavermonitorUwallpaper,
  script,
  searchdollar,
  searchvisual,
  selectcirclearea,
  sendemail,
  servingdome,
  sharelink,
  shelf,
  shell,
  shield,
  shield1,
  shieldcheck,
  shieldcross,
  shift,
  shipmentadd,
  shipmentcheck,
  shipmentdownload,
  shipmentremove,
  shipmentupload,
  shippingbox,
  shippingtruck,
  shoppingbaghandbag,
  shoppingbasket,
  shoppingbasket1,
  shoppingcart,
  shoppingcart1,
  shoppingcart2,
  shoppingcartadd,
  shoppingcartcheck,
  shoppingcartsubtract,
  shovelrake,
  shredder,
  shrimp,
  shrinkhorizontal,
  shuffle,
  sigma,
  signat,
  signcrosssquare,
  signhashtag,
  signage,
  signage1,
  signalfull,
  signalloading,
  signallow,
  signalmedium,
  signalnone,
  skull,
  slack,
  sleep,
  smileyangry,
  smileycool,
  smileycrying,
  smileycute,
  smileydrool,
  smileyemojikissnervous,
  smileyemojiterrified,
  smileygrumpy,
  smileyhappy,
  smileyinlove,
  smileykiss,
  smileylaughing,
  smileymask,
  smileynauseas,
  smileysmirk,
  smileysparks,
  smileysurprised,
  smileythrowup,
  smileyveryshocked,
  smokedetector,
  smokingarea,
  snorkle,
  snowflake,
  songrecommendation,
  sortdescending,
  soshelpemergencysign,
  spadessymbol,
  speaker,
  speaker1,
  spiralshape,
  splitvertical,
  spotify,
  spraypaint,
  sprout,
  squarebracketscircle,
  squarecap,
  squareclock,
  squarerootxcircle,
  star,
  star1,
  starbadge,
  startup,
  steeringUwheel,
  stepsnumber,
  stethoscope,
  stock,
  store,
  store1,
  storecomputer,
  straightcap,
  straightface,
  strategytasks,
  strawberry,
  stream,
  streetroad,
  streetsign,
  subscriptioncashflow,
  subtract,
  subtractcircle,
  subtractsquare,
  suncloud,
  synchronizedisable,
  synchronizeUwarning,
  syringe,
  tablelamp,
  tabletcapsule,
  tag,
  tag1,
  takeoff,
  tallhat,
  tapecassetterecord,
  target,
  target1,
  tasklist,
  teacup,
  telegram,
  telescope,
  testtube,
  textflowrows,
  textsquare,
  textstyle,
  thermometer,
  threatbrowser,
  threatdocument,
  threatfolder,
  ticket,
  tickets,
  tidalUwave,
  tiktok,
  tinder,
  toast,
  toiletman,
  toiletsignmanUwoman,
  toiletUwomen,
  tooth,
  trafficcone,
  transfermotorcycle,
  transfervan,
  tree,
  tree1,
  trendingcontent,
  triangleflag,
  trophy,
  twitter,
  twofingerdraghotizontal,
  twofingertap,
  underlinetext,
  uploadbox,
  uploadcircle,
  uploadcomputer,
  uploadfile,
  usbdrive,
  useraddplus,
  usercheckvalidate,
  usercirclesingle,
  useridentifiercard,
  usermultiplecircle,
  usermultiplegroup,
  userprofilefocus,
  userprotection,
  userremovesubtract,
  usersingleneutralmale,
  usersynconlineinperson,
  verticalslidersquare,
  videoswapcamera,
  virtualreality,
  virusantivirus,
  visible,
  voicemail,
  voicemailoff,
  voicescan,
  volcano,
  volumedown,
  volumelevelhigh,
  volumelevellow,
  volumeleveloff,
  volumemute,
  volumeoff,
  vpnconnection,
  vrheadset,
  vrheadset1,
  Uwaitingappointmentscalendar,
  Uwallet,
  Uwalletpurse,
  Uwaningcresentmoon,
  Uwarehouse,
  Uwarningoctagon,
  Uwarningtriangle,
  Uwatch,
  Uwatch1,
  Uwatchcirclecharging,
  Uwatchcircleheartbeatmonitor,
  Uwatchcircleheartbeatmonitor1,
  Uwatchcirclemenu,
  Uwatchcircletime,
  Uwaterglass,
  Uwavesignal,
  Uwavesignalsquare,
  Uwavinghand,
  Uweb,
  Uwebcam,
  Uwebcamvideo,
  Uwebcamvideocircle,
  Uwebcamvideooff,
  Uwhatsapp,
  Uwheelchair,
  Uwheelchair1,
  Uwidget,
  Uwifi,
  Uwifiantenna,
  Uwifidisabled,
  Uwifihorizontal,
  Uwifirouter,
  Uwindflow,
  Uwindflow1,
  Uwindmill,
  Uwindows,
  Uwine,
  Uwomansymbol,
  Uworkspacedesk,
  Uwrench,
  xbox,
  xrpcircle,
  yinyangsymbol,
  yuan,
  yuancircle,
  zerohunger,
  zodiac,
  zodiac1,
  zodiac2,
  zodiac3,
  zodiac4,
  zodiac5,
  zodiac6,
  zodiac7,
  zodiac8,
  zodiac9,
  zodiac10,
  zodiac11,
  stageClockHand,
  streamLineFileCheck,
  mail,
  tel,
  house,
  earthGlobe,
  streamlineCoreLineFileText,
  streamlineCoreDownload,
  StreamlineCoreFileCancel,
  ButtonPause,
  coreLineMoney,
  copy,
};
