import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { ModalAction } from "components/Modal";
import { Pill } from "components/Pill";

import { Truncate } from "../../components/Truncate";
import styles from "./MatchPaymentsDetails.module.scss";
import { useUpdateStatusPaymentMutation } from "lib/slices/presentationServiceAPISlice";

type Props = {
  id: string;
  customerName: string;
  dueDate: string;
  totalAmount: string;
  paymentReference: string;
  customerSSN: string;
  paymentId: string;
  moveToMatchedPaymentsTab: () => void
};

export function SearchedInvoice({
  id,
  customerName,
  dueDate,
  totalAmount,
  paymentReference,
  customerSSN,
  paymentId,
  moveToMatchedPaymentsTab
}: Props) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [matched, setMatched] = useState(false);
  
  const [updateStatusPayment, { isLoading }] = useUpdateStatusPaymentMutation();


  const handleCloseModalForm = async () => {
    setShowModal(false);

    await updateStatusPayment({
      payments: [{
          id: paymentId,
          status: "matched_manual",
          invoiceId: id
      }]
    });

    moveToMatchedPaymentsTab();
  };

  return (
    <section className={styles.singleInvoice}>
      <div>
        <h4>{t("pages.payments.invoiceId")}</h4>
        <div className={styles.invoiceIdPill}>
          <Pill
            intent="Dark"
            text={id}
            href={`/cases/${id}`}
            style={{
              display: "inline-block",
              paddingTop: "var(--spacing-8)",
              textDecoration: "underline",
            }}
            separateWindow
          />
        </div>
      </div>
      <div className={styles.singleInvoiceTwoColumns}>
        <div>
          <h4>{t("pages.payments.customerName")}</h4>
          <p>{customerName}</p>
        </div>
      </div>
      <div className={styles.singleInvoiceTwoColumns}>
        <div>
          <h4>{t("pages.payments.dueDate")}</h4>
          <p>{dueDate}</p>
        </div>
        <div>
          <h4>{t("pages.payments.columnHeaders.amount")}</h4>
          <p>{totalAmount}</p>
        </div>
      </div>
      <div className={styles.singleInvoiceTwoColumns}>
        <div>
          <h4>{t("pages.payments.columnHeaders.paymentReference")}</h4>
          <Truncate value={paymentReference} />
        </div>
        <div>
          <h4>{t("pages.payments.SSN")}</h4>
          <p>{customerSSN}</p>
        </div>
      </div>

      <br />
      <Button
        text={t("pages.payments.matchPaymentWithInvoice")}
        intent={!matched ? "black" : "primary-light"}
        outline={false}
        onClick={() => {
          setShowModal(true);
          setMatched(true);
        }}
        style={{
          border: matched ? "1px solid var(--content-subtle)" : "none",
          width: "100%",
          marginBottom: "var(--spacing-8)",
          marginTop: "var(--spacing-40)",
        }}
      />

      <ModalAction
        direction="column"
        isOpen={showModal}
        onClose={handleCloseModalForm}
        intent="success"
        title={t("pages.payments.success")}
        content={() => (
          <div>{t("pages.payments.paymentMatchedSuccesfully")}</div>
        )}
        ctaBtns={[
          {
            text: t("close"),
            intent: "primary-light",
            onClick: handleCloseModalForm,
            disabled: isLoading,
          },
        ]}
      />
    </section>
  );
}
