import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import styles from "./CaseEventLog.module.scss";

type Props = {
  invoiceNo: string;
  senderPosition?: "left" | "right";
  event: Case["histories"][0];
};

export function CardEventLog({ invoiceNo, event, senderPosition }: Props) {
  const { t } = useTranslation();

  const formattedDate = useMemo(() => {
    const date = new Date(event.date);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    const formattedTime = date.toTimeString().split(" ")[0];
    return `${formattedDate.toUpperCase()} | ${formattedTime}`;
  }, [event.date]);

  return (
    <article className={styles.eventLog}>
      {senderPosition === "left" && <div className={styles.profilePic} />}

      <div className={styles.card}>
        <div className={styles.eventInfo}>
          <Text as="p" style="h5" className={styles.capitalize}>
            {t(`pages.case.history.${event.type}`, {type: event.messageData.type || ""})}
          </Text>
          {event.messageData.subject && (
            <Text as="p" style="h5">
              <span>{t("pages.case.subject")}</span> {event.messageData.subject}
            </Text>
          )}
          {event.messageData.from && (
            <Text as="p" style="h5">
              <span>{t("pages.case.from")}</span> {event.messageData.from}
            </Text>
          )}

          <div className={styles.badge}>
            <Text as="p" style="detail" strong color="--content-subtle">
              {formattedDate}
            </Text>
          </div>
        </div>

        <div className={styles.cardRight}>
          <Pill text={"Invoiced"} intent="Dark" small />

          {event.messageData.pdfUrl && (
            <div className={styles.pdfContainer}>
              <Icon name="paperclip" size={16} color="var(--content-subtle)" />
              <div>
                <Text as="p" style="detail" color="--content-subtle">
                  {t("pages.case.pdfDocument")}
                </Text>
                <Text as="p" style="bodySmall" strong>
                  {t("pages.case.invoiceNo", {
                    id: invoiceNo,
                  })}
                </Text>
              </div>
              <a
                href={event.messageData.pdfUrl}
                download={`invoice-${invoiceNo}`}
              >
                <Button icon={{ name: "streamlineCoreDownload", size: 18 }} />
              </a>
            </div>
          )}
        </div>
      </div>

      {senderPosition === "right" && <div className={styles.profilePic} />}
    </article>
  );
}
