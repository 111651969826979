import { Button } from "components/Button";

import styles from "./MatchPaymentsDetails.module.scss";

type Props = {
  current: number;
  all: number;
  onChangeIndex: (index: number) => void;
};

export function MatchpaymentsDetailsPagination({
  current,
  all,
  onChangeIndex,
}: Props) {
  return (
    <div className={styles.matchPaymentsPagination}>
      <Button
        disabled={current === 0 || current === all}
        icon={{
          name: "chevronLeft",
          size: 14,
        }}
        style={{
          borderWidth: "1px",
          width: "36px",
          height: "36px",
        }}
        onClick={() => {
          onChangeIndex(current - 1);
        }}
      />

      <div>
        {current + 1} of {all}
      </div>

      <Button
        disabled={current + 1 === all}
        icon={{
          name: "chevronRight",
          size: 14,
        }}
        style={{
          borderWidth: "1px",
          width: "36px",
          height: "36px",
        }}
        onClick={() => {
          onChangeIndex(current + 1);
        }}
      />
    </div>
  );
}
