import { createColumnHelper } from "@tanstack/react-table";

import { Pill } from "components/Pill";

const columnHelper = createColumnHelper<CustomerRes>();

export const customerColumns = [
  columnHelper.accessor("customerName", {
    header: "Name",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usercirclesingle",
    },
  }),
  columnHelper.accessor("customerNo", {
    header: "Customer NO",
    cell: (info) => (
      <Pill
        text={info.row.original.customerNo}
        intent="Link"
        href={`/customer/${info.row.original.id}`}
        style={{
          alignItems: "flex-start"
        }}
      />
    ),
    meta: {
      headerIcon: "userprofilefocus",
    },
  }),
  columnHelper.accessor("customerAddress.countryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
    },
  }),
  // columnHelper.accessor("customerTotalRemainingBalance", {
  //   header: "Total Remaining Balance",
  //   cell: (info) => `${info.getValue()}`,
  //   meta: {
  //     headerIcon: "bagdollar",
  //     filterType: "text",
  //   },
  // }),
  // columnHelper.accessor("numberOfActiveCases", {
  //   header: "Open Cases",
  //   cell: (info) => info.getValue(),
  //   meta: {
  //     headerIcon: "streamlineCoreLineFileText",
  //     filterType: "text",
  //   },
  // }),

  // Filters
  columnHelper.display({
    id: "trb",
    header: "Total Remaining Balance",
    meta: {
      hidden: true,
      filterType: "text",
    },
  }),
  columnHelper.display({
    id: "profile",
    header: "Profile",
    meta: {
      hidden: true,
      filterType: "checkboxes",
      filterOptions: ["VIP", "Normal", "Default", "Customized"],
    },
  }),
  columnHelper.display({
    id: "openCases",
    header: "Open Cases",
    meta: {
      hidden: true,
      filterType: "text",
    },
  }),
];

export const customerCOColumns = [
  customerColumns[0],
  customerColumns[1],
  columnHelper.accessor("clientName", {
    header: "Client",
    cell: (info) => info.getValue() ?? info.row.original.clientName,
    meta: {
      headerIcon: "usermultiplegroup",
    },
  }),
  ...customerColumns.slice(2),
];
