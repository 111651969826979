import {
  LazyQueryTrigger,
  TypedUseQueryStateResult,
  UseLazyQuery,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  SortingState,
  ColumnFiltersState,
  ColumnDef,
  CoreRow,
  PaginationState,
  Table,
} from "@tanstack/react-table";
import {
  useState,
  useEffect,
  forwardRef,
  Ref,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";

import { QueryDefinition } from "@reduxjs/toolkit/query";

import { inDateRange } from "lib/dateUtils";
import {
  QUERY_KEYS,
  resolveState,
  updateUrlWithParams,
} from "lib/filtersToURL";

import styles from "./Table.module.scss";
import { TableBody } from "./TableBody";
import { TableTop } from "./TableTop";

type TableProps<TData> = {
  fetchData: UseLazyQuery<any>;
  columns: ColumnDef<any, any>[];
  filters?: any;
  header?: boolean;
  headerCta?: React.ReactNode;
  mobileElement?: ({ rowData }: { rowData: any[] }) => JSX.Element;
  children?: React.ReactNode;
  CustomTable?: (props: { table: any }) => JSX.Element;
  pageSize?: number;
  setData?: (data: React.SetStateAction<TData[]>) => void;
  selectedRows?: CoreRow<TData>[];
  deleteRows?: boolean;
  onRowDelete?: (rows: CoreRow<TData>[]) => void;
  searchPlaceholder?: string;
  borderedTable?: boolean;
  rowCount?: number;
  onSelectedRow?: (selected: boolean) => void;
  noDataAvailableText?: string;
};

export const TableServer = forwardRef(function TableServer<TData>(
  props: TableProps<TData>,
  ref: Ref<Table<TData>>,
) {
  const {
    fetchData,
    columns,
    filters,
    header,
    headerCta,
    mobileElement,
    children,
    CustomTable,
    pageSize = 1,
    setData,
    selectedRows = [],
    deleteRows,
    onRowDelete,
    searchPlaceholder,
    borderedTable,
    rowCount,
    onSelectedRow,
    noDataAvailableText,
  } = props;

  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>();
  const [, setDeleteModalOpen] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [fetch, { data, isLoading }] = fetchData() as unknown as [
    LazyQueryTrigger<QueryDefinition<string, any, any, any>>,
    TypedUseQueryStateResult<TableResponse<TData>, unknown, any>,
  ];

  // Gets URL params and sets filters, sorting and pagination
  useEffect(() => {
    const params = window.location.search;
    const queryParams = new URLSearchParams(params);

    if (queryParams.has(QUERY_KEYS.sort)) {
      setSorting([
        {
          id: queryParams.get(QUERY_KEYS.sort) as string,
          desc:
            queryParams.get(QUERY_KEYS.order) === QUERY_KEYS.asc ? false : true,
        },
      ]);
    }

    if (queryParams.has(QUERY_KEYS.pagination)) {
      setPagination({
        pageIndex:
          parseInt(queryParams.get(QUERY_KEYS.pagination) as string) - 1,
        pageSize: 10,
      });
    }

    const columnFilters: ColumnFiltersState = [];
    queryParams.forEach((value, key) => {
      if (
        // @ts-expect-error key 'string' is not assignable to parameter of type 'QUERY_KEYS'
        [QUERY_KEYS.pagination, QUERY_KEYS.sort, QUERY_KEYS.order].includes(key)
      ) {
        return;
      }

      columnFilters.push({
        id: key,
        value: value.includes(",") ? value.split(",") : value,
      });
    });

    setColumnFilters(columnFilters);
  }, []);

  useEffect(() => {
    const params = window.location.search;
    const queryParams = new URLSearchParams(params);
    !queryParams.has(QUERY_KEYS.pagination) &&
      queryParams.set(
        QUERY_KEYS.pagination,
        (pagination.pageIndex + 1).toString(),
      );

    fetch(queryParams.toString());
  }, [pagination, sorting, columnFilters]);

  const table = useReactTable({
    data: data?.rows ?? [],
    columns,
    filterFns: {
      inDateRange,
    },
    initialState: {
      pagination: {
        pageSize,
      },
    },
    state: {
      globalFilter,
      columnFilters,
      sorting,
      pagination,
    },
    pageCount: data?.totalPages,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: (sortState) => {
      setSorting(sortState);
      const resolvedSorting = resolveState(sortState, sorting);
      if (resolvedSorting[0]) {
        updateUrlWithParams(
          QUERY_KEYS.sort,
          resolvedSorting[0].id.replace("_", "."),
        );
        updateUrlWithParams(
          QUERY_KEYS.order,
          resolvedSorting[0].desc ? QUERY_KEYS.desc : QUERY_KEYS.asc,
        );
      } else {
        updateUrlWithParams(QUERY_KEYS.sort, undefined);
        updateUrlWithParams(QUERY_KEYS.order, undefined);
      }
    },
    onColumnFiltersChange: (filters) => {
      const resolvedFilters = resolveState(filters, columnFilters);
      setColumnFilters((oFilters) => {
        const oldFilters = [...oFilters];
        oldFilters.forEach((oF) => updateUrlWithParams(oF.id, undefined));
        resolvedFilters.forEach((f) =>
          updateUrlWithParams(f.id.replace("_", "."), f.value as string),
        );

        return resolvedFilters;
      });
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    rowCount: rowCount,
    onPaginationChange: (state) => {
      const resolvedPagination = resolveState(state, pagination);
      setPagination(state);
      updateUrlWithParams(
        QUERY_KEYS.pagination,
        (resolvedPagination.pageIndex + 1).toString(),
      );
    },
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    meta: {
      resetRow: (rowIndex: number, rowData: any) => {
        setData?.((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return rowData;
            }
            return row;
          }),
        );
      },
    },
  });

  useEffect(() => {
    if (!table.getIsAllRowsSelected()) {
      onSelectedRow?.(table.getIsSomePageRowsSelected());
    }
  }, [table.getIsSomePageRowsSelected(), table.getIsAllRowsSelected()]);

  useImperativeHandle(ref, () => {
    return table;
  });

  const headers = columns
    .map((column) => column.header)
    .filter((c) => c)
    .join(", ");

  if (CustomTable) {
    return <CustomTable table={table} />;
  }

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.tableMain}>
          <div>
            {header && (
              <TableTop
                table={table}
                ctaBtn={headerCta}
                filters={filters}
                deleteRows={deleteRows}
                selectedRows={selectedRows}
                searchPlaceholder={
                  searchPlaceholder ?? t("table.searchPlaceholder", { headers })
                }
                setDeleteModalOpen={setDeleteModalOpen}
              />
            )}
            <TableBody
              table={table}
              MobileElement={mobileElement}
              selectedRows={selectedRows}
              borderedTable={borderedTable}
              isLoading={isLoading}
              totalPages={data?.totalPages}
              noDataAvailableText={noDataAvailableText}
            >
              {children}
            </TableBody>
          </div>
        </div>
      </div>
    </>
  );
});
