import { CoreRow, createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { ContextMenu } from "components/ContextMenu";
import { DashboardHeader } from "components/DashboardHeader";
import { Pill } from "components/Pill";
import { Table } from "components/Table";

import { readableId } from "lib/helpers";
import { useHead } from "lib/hooks/useHead";
import {
  useDeleteClientMutation,
  useGetClientsQuery,
} from "lib/slices/presentationServiceAPISlice";

import styles from "./PageSAClients.module.scss";
import { useState } from "react";
import { ModalAction } from "components/Modal";

const columnHelper = createColumnHelper<Client>();

export function PageSAClients() {
  const { t } = useTranslation();
  const { data, isLoading } = useGetClientsQuery();
  const [softDelete] = useDeleteClientMutation();
  const navigate = useNavigate();
  useHead(t("pages.superAdmin.clients.header"));

  const [showModal, setShowModal] = useState<{
    visiblity: boolean,
    row: CoreRow<Client>["original"] | null
  }>({
    visiblity: false,
    row: null
  });

  const handleToggleModal = (visibility: boolean, row?: CoreRow<Client>["original"]) => {
    setShowModal({
      visiblity: visibility,
      row: row ?? null
    });
  };

  const items = data && data.data;

  const deleteClient = (row: CoreRow<Client>["original"]) => {
    softDelete(row.id);
  };

  const columns = [
    columnHelper.accessor("name", {
      header: t("pages.superAdmin.clients.tableColumns.clientName"),
      cell: (info) => (
        <div className={styles.columnMultiple}>
          <div className={styles.clientLogo}>
            {info.row.original.logo_uri && (
              <img src={info.row.original.logo_uri} />
            )}
          </div>
          <p>{info.row.original.name}</p>
        </div>
      ),
      meta: {
        headerIcon: "usermultiplegroup",
      },
    }),
    columnHelper.accessor("id", {
      header: t("pages.superAdmin.clients.tableColumns.id"),
      cell: (info) => (
        <Link to={`/sa/clients/${info.getValue()}`}>
          <Pill text={readableId(info.getValue())} intent="Link" />
        </Link>
      ),
      meta: {
        headerIcon: "usermultiplegroup",
      },
    }),
    columnHelper.accessor("legalEntity", {
      header: t("pages.superAdmin.clients.tableColumns.legalEntity"),
      meta: {
        headerIcon: "bank",
      },
    }),
    columnHelper.accessor("orgNr", {
      header: t("pages.superAdmin.clients.tableColumns.orgNr"),
      meta: {
        headerIcon: "mail",
      },
    }),
    columnHelper.accessor("address1", {
      id: "address",
      header: t("pages.superAdmin.clients.tableColumns.address"),
      meta: {
        headerIcon: "home1",
      },
    }),
    columnHelper.accessor("visibleContactAddress", {
      header: t("pages.superAdmin.clients.tableColumns.email"),
      meta: {
        headerIcon: "mail",
      },
    }),
    columnHelper.accessor("website", {
      header: t("pages.superAdmin.clients.tableColumns.website"),
      cell: (info) => (
        <a href={info.getValue()}>
          <Pill text={info.getValue()} intent="Link" />
        </a>
      ),
      meta: {
        headerIcon: "Uweb",
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (info) => (
        <ContextMenu
          menuItems={[
            {
              icon: "informationcircle",
              label: "View More & Edit Data",
              onClick: () => navigate(`/sa/clients/${info.row.original.id}`),
            },
            {
              icon: "recyclebin",
              label: "Delete Client",
              onClick: () => {
                handleToggleModal(true, info.row.original);
              },
            },
          ]}
        >
          <Button icon={{ name: "horizontalmenucircle" }} intent="clear" />
        </ContextMenu>
      ),
    }),
  ];

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.superAdmin.clients.header")}
        subHeader={"Manage your clients from here."}
        badge={`${items?.length} clients`}
      />

      <Table
        data={items}
        columns={columns}
        header={true}
        isLoading={isLoading}
        headerCta={
          <Button
            intent="black"
            text={t("pages.superAdmin.clients.newClient")}
            icon={{ name: "addcircle", intent: "white" }}
            onClick={() => navigate("/sa/clients/new")}
          />
        }
      />

      {showModal.visiblity && (
        <ModalAction
          direction="column"
          title={t("pages.superAdmin.clients.attention")}
          content={() => {
            return <p>{t("pages.superAdmin.clients.toDeleteClient", {
              name: showModal.row?.name
            })}</p>;
          }}
          isOpen={showModal.visiblity ? true : false}
          intent="danger"
          ctaBtns={[
            { text: t("pages.superAdmin.clients.cancel"),
              intent: "primary-light",
              onClick: () => handleToggleModal(false)
            },
            { text: t("pages.superAdmin.clients.deleteClient"),
              intent: "primary", 
              onClick: () => {
                handleToggleModal(false);
                if(showModal.row){
                  deleteClient(showModal.row);
                }
              }
            },
          ]}
          onClose={() => handleToggleModal(false)}
        />
      )}
    </main>
  );
}
