import { classNames } from "lib/classNames";
import styles from "./Skeleton.module.scss";

type Props = {
  width?: string | number;
  height?: string | number;
  customClasses?: string | string[];
};

export function Skeleton({ width, height, customClasses }: Props) {
  let applyCustomClasses;

  if(customClasses) {
    if(Array.isArray(customClasses)) {
      applyCustomClasses = [...customClasses].join(" ");
    } else {
      applyCustomClasses = customClasses;
    }
  }

  return <div className={classNames(styles.skeleton, applyCustomClasses)} style={{ width, height }} />;
}
