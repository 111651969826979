import { Table as ReactTable, Header } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { DropdownFilters } from "components/Dropdowns";
import { Icon } from "components/Icon";

import useMediaQuery from "lib/hooks/useMediaQuery";

import breakpoints from "styles/mixins.scss";

import styles from "./Table.module.scss";
import { TableFilter } from "./TableFilter";

function getFilters<TData>(table: ReactTable<TData>) {
  return table.getLeafHeaders().map((header: Header<TData, unknown>) => {
    const canFilter = header.column.columnDef.meta?.filterType ? true : false;
    if (!canFilter) return null;

    return <TableFilter column={header.column} key={header.id} />;
  });
}

interface TableFiltersMobileProps<TData> {
  table: ReactTable<TData>;
}

export function TableFiltersMobile<TData>({
  table
}: TableFiltersMobileProps<TData>) {
  const filters = getFilters(table);

  return (
    <>
      <DropdownFilters
        activeFilters={table.getState().columnFilters.length}
        onDeleteClick={() => table.resetColumnFilters()}
      >
        {filters}
      </DropdownFilters>
    </>
  );
}

interface TableFiltersProps<TData> {
  table: ReactTable<TData>;
}

export function TableFilters<TData>({ table }: TableFiltersProps<TData>) {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.desktop})`);

  const filters = getFilters(table);

  if (isTablet) {
    return (
      <div className={styles.filtersContainer}>
        <div className={styles.header}>
          <Icon name="filter" intent="black" />
          <p>{t("filters")}</p>
        </div>
        {filters}
      </div>
    );
  }

  return <TableFiltersMobile table={table} />;
}
