import { Link } from "react-router-dom";

import { classNames } from "lib/classNames";

import styles from "./Pill.module.scss";

type Props = {
  text: string | React.ReactNode;
  intent?: "Dark" | "Light" | "Active" | "Purple" | "Link";
  href?: string;
  label?: string;
  style?: React.CSSProperties;
  separateWindow?: boolean;
  small?: boolean;
  color?: string;
};

export function Pill({
  text,
  label,
  intent = "Light",
  href,
  style,
  separateWindow,
  color,
  small,
}: Props) {
  const pill = (
    <div className={styles.pill} style={style}>
      {label && <p className={styles.label}>{label}</p>}
      <p
        className={classNames(styles.pillContainer, styles[`pill${intent}`], {
          [styles.pillSmall]: small ?? false,
        })}
        style={{ color }}
      >
        {text}
      </p>
    </div>
  );

  if (href) {
    return (
      <Link
        to={href}
        target={separateWindow ? "_blank" : ""}
        style={{ textDecoration: "none" }}
      >
        {pill}
      </Link>
    );
  }

  return pill;
}
