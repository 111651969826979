import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { DashboardHeader } from "components/DashboardHeader";
import { FormSANewClient } from "components/FormSANewClient";

import { useGetClientQuery } from "lib/slices/presentationServiceAPISlice";

import styles from "./PageSAClients.module.scss";

export function PageSAClientsEdit() {
  const { t } = useTranslation();
  const { clientId } = useParams() as { clientId: string };
  const { data } = useGetClientQuery(clientId);

  const client = data && data.data;

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.superAdmin.clients.header")}
        backBtn
        breadcrumbs={[
          { title: "Clients", link: "/sa/clients" },
          { title: client?.name ?? "" },
        ]}
      />

      <section className={styles.clientContainer}>
        <div>
          <div className={styles.clientLogo}>
            {
              client?.logo_uri && <img src={client?.logo_uri} />
            }
          </div>
          <h3>{client?.name}</h3>
        </div>

        {client && (
          <FormSANewClient clientId={client?.id} defaultData={client} />
        )}
      </section>
    </main>
  );
}
