import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";

import { classNames } from "lib/classNames";
import { copyToClipboard } from "lib/helpers";

import styles from "./MatchPaymentsDetails.module.scss";

type Props = {
  keyProp: string;
  value:
    | string
    | (
        | "Matched"
        | "Not Matched"
        | "Already Paid"
        | "Debt Collection"
        | "Invoice Debt Collection"
        | "Not Processed"
        | "Automatic"
        | "Manual"
      )[];
  shownNotification: string;
  onSetShownNotification: Dispatch<SetStateAction<string>>;
};

export function MatchPaymentsDetailsInfoCell({
  keyProp,
  value,
  shownNotification,
  onSetShownNotification,
}: Props) {
  const { t } = useTranslation();
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const handleShowNotification = () => setShowCopyNotification(true);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (showCopyNotification) {
      timerRef.current = setTimeout(() => {
        setShowCopyNotification(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timerRef.current as NodeJS.Timeout);
    };
  }, [showCopyNotification]);

  useEffect(() => {
    if (shownNotification !== keyProp) {
      setShowCopyNotification(false);
      clearTimeout(timerRef.current as NodeJS.Timeout);
    }
  }, [shownNotification]);

  const translatedHeading = t(`pages.payments.paymentDetailsCard.${keyProp}`);

  return (
    <div>
      <div>
        <h4>{translatedHeading}</h4>
        <p>{value || "-"}</p>
      </div>
      <div className={styles.matchPaymentsInfoCardContentCopyIconWrapper}>
        {showCopyNotification && (
          <div className={styles.copyContentNotification}>
            {translatedHeading}
          </div>
        )}
        {value !== "" && (
          <Button
            title={t("pages.payments.copy")}
            icon={{
              name: "copy",
              size: 24,
              color: showCopyNotification
                ? "var(--content-emphasis)"
                : "var(--content-subtle)",
            }}
            intent="clear"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className={classNames({
              [styles.matchPaymentsInfoCardContentCopyIcon]:
                showCopyNotification,
            })}
            onClick={() => {
              handleShowNotification();
              onSetShownNotification(keyProp);
              copyToClipboard(value as string);
            }}
          />
        )}
      </div>
    </div>
  );
}
