import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./StatusBadge.module.scss";

type Props = {
  status:
    | "Matched"
    | "Not Matched"
    | "Already Paid"
    | "Invoice Debt Collection"
    | "Debt Collection"
    | "Not Processed"
    | "Automatic"
    | "Manual";
};

// TODO: add more statuses
// Note the format of the status is unclear for now so it will compare as strings values
export function StatusBadge({ status }: Props) {
  if (status.toLowerCase() === "matched") {
    return (
      <div className={styles.badge}>
        <Icon name="check" size={15} color="#027A48" />
        <span>{status}</span>
      </div>
    );
  }

  if (status.toLowerCase() === "not matched") {
    return (
      <div
        className={classNames(styles.badge, styles.notMatched)}
      >
        <Icon name="delete1" size={15} color="#B42318" />
        <span>{status}</span>
      </div>
    );
  }

  if (status.toLowerCase() === "already paid") {
    return (
      <div
        className={classNames(styles.badge, styles.paid)}
      >
        <Icon name="Uwarningtriangle" size={15} color="#fff" />
        {status}
      </div>
    );
  }

  if (status.toLowerCase() === "debt collection" || status.toLowerCase() === "invoice debt collection") {
    return (
      <div
        className={classNames(styles.badge, styles.debtCollection)}
      >
        {status}
      </div>
    );
  }

  if (status.toLowerCase() === "not processed") {
    return (
      <div
        className={classNames(styles.badge, styles.ignored)}
      >
        {status}
      </div>
    );
  }

  if (status.toLowerCase() === "matched auto") {
    return (
      <div
        className={classNames(styles.badge, styles.ignored)}
      >
        <Icon name="cyborg" size={15} color="#333" />
        {status}
      </div>
    );
  }

  if (status.toLowerCase() === "matched manual") {
    return (
      <div
        className={classNames(styles.badge, styles.ignored)}
      >
        <Icon name="Uwavinghand" size={15} color="#333" />
        {status}
      </div>
    );
  }

  return <div className={styles.badge}>Status</div>;
}
