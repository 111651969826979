import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { Pill } from "components/Pill";

import { formatCurrency } from "lib/formatNumber";
import { readableId } from "lib/helpers";

const columnHelper = createColumnHelper<SingleCustomerRes["cases"][0]>();

export const customerCaseColumns = [
  columnHelper.accessor("invoice.invoiceNo", {
    header: "Case ID",
    cell: ({ row }) => (
      <Link
        to={`/customer/${row.original.invoice.customerId}/${row.original.invoice.id}`}
        style={{ textDecoration: "none" }}
      >
        <Pill
          text={
            row.original.invoice.invoiceNo ??
            readableId(row.original.invoice.id)
          }
          intent="Link"
        />
      </Link>
    ),
    meta: {
      headerIcon: "streamLineFileCheck",
    },
  }),
  columnHelper.accessor("invoice.paymentReference", {
    header: "Reference Number",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "streamLineFileCheck",
    },
  }),
  columnHelper.accessor("invoice.totalAmount", {
    header: "Total Amount",
    cell: ({ row }) => (
      <>
        {formatCurrency(
          parseFloat(row.original.invoice.totalAmount),
          row.original.invoice.currency,
        )}
      </>
    ),
    meta: {
      headerIcon: "bagdollar",
    },
  }),
  columnHelper.accessor("invoice.dueDate", {
    header: "Due Date",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "blankcalendar",
    },
  }),
  columnHelper.accessor("lastInvoicePayment", {
    header: "Last Payment",
    cell: ({ row }) => (
      <>
        {Object.entries(row.original.lastInvoicePayment).map(([key, value]) => (
          <p key={key}>{value}</p>
        ))}
      </>
    ),
    meta: {
      headerIcon: "stageClockHand",
    },
  }),
];
