/**
 * Custom hook that detects when a scrollable element reaches the bottom.
 * 
 * @param {Object} props - The hook's props.
 * @param {RefObject<HTMLDivElement>} props.wrapperRef - The ref to the scrollable wrapper element.
 * @param {Function} props.callbackReachBottom - The callback function to be called when the scrollable element reaches the bottom.
 * @param {Function} [props.resetCallback] - The optional callback function to be called when the scrollable element is not at the bottom anymore.
 * 
 * @returns {void}
 */
import { RefObject, useEffect } from "react";

type Props = {
  wrapperRef: RefObject<HTMLDivElement>;
  callbackReachBottom: () => void;
  resetCallback?: () => void;
};


export function useDetectScrollBottom({
  wrapperRef,
  callbackReachBottom,
  resetCallback,
}: Props) {
  useEffect(() => {
    const handleScroll = () => {
      if (!wrapperRef.current || !wrapperRef.current.clientHeight) {
        return;
      }

      if (
        wrapperRef.current.scrollTop + wrapperRef.current.clientHeight + 100 >=
        wrapperRef.current?.scrollHeight
      ) {
        callbackReachBottom();
      } else {
        resetCallback?.();
      }
    };

    wrapperRef.current?.addEventListener("scroll", handleScroll);

    return () => {
      wrapperRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [callbackReachBottom, resetCallback]);
}
