import { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import { BaseInputTemplateProps } from "@rjsf/utils";

import { Icon } from "components/Icon";

import styles from "./BaseInputTemplate.module.scss";

/**
 * This component extends the BaseInputTemplate from rjsf.
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#baseinputtemplate
 */
export function BaseInputTemplate(props: BaseInputTemplateProps) {
  const {
    id,
    type,
    placeholder,
    disabled,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    rawErrors = [],
    value,
    icon,
    uiSchema,
    schema,
    name,
  } = props;

  const hasError = rawErrors.length > 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    onChange?.(val === "" ? undefined : val);
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    onBlur?.(id, event.target.value);
  };

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    onFocus?.(id, event.target.value);
  };

  const label = uiSchema?.["ui:title"] || schema.title;

  return (
    <div className={styles.baseInputContainer}>
      {label && (
        <div className={styles.formFieldLabel}>
          <label htmlFor={id}>{label}</label>
        </div>
      )}

      <div className={styles.inputGroup}>
        <div className={styles.inputGroupContainer}>
          {icon && <Icon name={icon} style={{ marginRight: "8px" }} />}

          <input
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={value}
            onChange={onChangeOverride || handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            aria-invalid={hasError}
            maxLength={schema?.maxLength}
            minLength={schema?.minLength}
          />
        </div>
      </div>

      {/* Helper text and link */}
      <div className={styles.helperContainer}>
        {uiSchema?.["ui:help"] && (
          <div className={styles.helperText}>{uiSchema["ui:help"]}</div>
        )}
        {uiSchema?.["ui:link"] && (
          <Link to={uiSchema["ui:link"].href} className={styles.linkText}>
            {uiSchema["ui:link"].text}
          </Link>
        )}
      </div>
    </div>
  );
}
