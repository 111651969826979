import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import styles from "./DropdownUser.module.scss";
import { DropdownUserRow } from "./DropdownUserRow";

export const DropdownBtn = (data: any) => (
  <div className={styles.dropdownBtn}>
    <p className={styles.dropdownBtnTitle}>{data.title}</p>
    <p className={styles.dropdownBtnEmail}>{data.user.email}</p>
  </div>
);

export const DropdownContent = (data: any) => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className={styles.dropdownContent}>
      <DropdownUserRow
        title={t("logout")}
        icon="logout"
        onClick={() => auth.signoutRedirect()}
      />
    </div>
  );
};
