import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./NoResults.module.scss";

type Props = {
  text: string;
  className?: string;
};

export function NoResults({ text, className }: Props) {
  return (
    <div className={classNames(styles.noResults, className)}>
      <div>
        <Icon name="informationcircle" color="var(--content-subtle)" />
        <h4>{text}</h4>
      </div>
    </div>
  );
}
