import { CoreRow } from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { RInioButton } from "ui-core";

import { DashboardHeader } from "components/DashboardHeader";
import { FormCreateUser } from "components/FormCreateUser";
import { Modal } from "components/Modal";
import { Table } from "components/Table";

import { useHead } from "lib/hooks/useHead";
import useMediaQuery from "lib/hooks/useMediaQuery";
import {
  useDeleteMemberMutation,
  useGetMembersQuery,
} from "lib/slices/presentationServiceAPISlice";

import breakpoints from "styles/mixins.scss";

import { tableMemberColumns } from "./MembersColumns";
import styles from "./PageMembers.module.scss";

export function PageMembers() {
  const { t } = useTranslation();
  const { data: members, isLoading, refetch } = useGetMembersQuery();
  const [deleteMutation] = useDeleteMemberMutation();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState<Member | undefined>(
    undefined,
  );
  const [selectedRows, setSelectedRows] = useState<CoreRow<Member>[]>([]);
  const { user } = useAuth();
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop})`);

  const isCustomerOperator =
    user?.profile.roles.includes("client-operator") ?? false;

  useHead(t("pages.members.header"));

  const addForDeletion = (row: CoreRow<Member>) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const columns = tableMemberColumns(
    selectedRows,
    addForDeletion,
    setEditModalOpen,
    isCustomerOperator,
    user?.profile.email,
  );

  const deleteRows = async (rows: CoreRow<Member>[]) => {
    Promise.all([
      rows.map(async (row) => {
        await deleteMutation({ id: row.original.id });
        await refetch();
      }),
    ]);
    setSelectedRows([]);
  };

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader
          title={t("pages.members.header")}
          subHeader={t("pages.members.subHeader")}
          badge={`${members?.length} users`}
        />
        <Table
          data={members}
          columns={columns}
          header={true}
          isLoading={isLoading}
          selectedRows={selectedRows}
          deleteRows={isCustomerOperator}
          onRowDelete={(rows) => deleteRows(rows)}
          // mobileElement={TableMembersMobile}
          headerCta={
            isCustomerOperator && (
              <RInioButton
                icon="plus"
                text={isDesktop ? t("pages.members.createUser") : undefined}
                onClick={() => setCreateModalOpen(true)}
              />
            )
          }
        />
      </main>

      <Modal
        isOpen={editModalOpen ? true : false}
        onClose={() => setEditModalOpen(undefined)}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            <h2>{t("pages.members.editUser")}</h2>
            <p>{t("newClaim.subHeader")}</p>
          </div>
          {editModalOpen && (
            <FormCreateUser
              defaultValues={editModalOpen}
              closeModal={() => setEditModalOpen(undefined)}
            />
          )}
        </div>
      </Modal>

      <Modal isOpen={createModalOpen} onClose={() => setCreateModalOpen(false)}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <h2>{t("pages.members.createUser")}</h2>
            <p>{t("newClaim.subHeader")}</p>
          </div>
          <FormCreateUser closeModal={() => setCreateModalOpen(false)} onResetRowsSelection={() => setSelectedRows([])} />
        </div>
      </Modal>
    </>
  );
}
