import { useState } from "react";
import { useTranslation } from "react-i18next";

import { PaymentColumns } from "types";

import { Button } from "components/Button";
import { ModalAction } from "components/Modal";

import { useUpdateStatusPaymentMutation } from "lib/slices/presentationServiceAPISlice";

import { StatusBadge } from "pages/Payments/components/StatusBadge";

import styles from "./MatchPaymentsDetails.module.scss";
import { MatchPaymentsDetailsInfoCell } from "./MatchPaymentsDetailsInfoCell";

type Props = {
  row: Omit<
    PaymentColumns,
    | "customerId"
    | "transactionType"
    | "paidDate"
    | "currency"
    | "transactionReference"
  >;
  onRemoveSelection: (triggerDeselecting?: boolean) => void;
};

export function MatchPaymentsDetailsInfoCard({
  row,
  onRemoveSelection,
}: Props) {
  const { t } = useTranslation();

  const [shownNotification, setShownNotification] = useState<string>("");
  const [updateStatusPayment, { isLoading }] = useUpdateStatusPaymentMutation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleToggleConfirmation = (type: boolean) => {
    setShowConfirmation(type);
    onRemoveSelection(!showConfirmation);
  };

  const handleUpdateStatus = async () => {
    setShowConfirmation(true);

    await updateStatusPayment({
      payments: [
        {
          id: row.id,
          status: "not_matched",
        },
      ],
    });
  };

  return (
    <>
      <div className={styles.matchPaymentsInfoCardHeader}>
        <h4>{t("pages.payments.paymentDetails")}</h4>
        <div className={styles.matchPaymentsInfoCardStatuses}>
          <StatusBadge
            key={row.status}
            status={row.status.split("_").join(" ") as PaymentColumns["status"]}
          />
          {row.statusReason && (
            <StatusBadge
              key={row.statusReason}
              status={
                row.statusReason
                  .split("_")
                  .join(" ") as PaymentColumns["status"]
              }
            />
          )}
        </div>
      </div>
      <div className={styles.matchPaymentsInfoCardContent}>
        {Object.entries(row)
          .filter(
            ([key]) =>
              key !== "id" && key !== "status" && key !== "statusReason",
          )
          .map(([key, value]) => {
            return (
              <MatchPaymentsDetailsInfoCell
                key={key}
                keyProp={key}
                value={value}
                onSetShownNotification={setShownNotification}
                shownNotification={shownNotification}
              />
            );
          })}

        <Button
          text={t("pages.payments.moveToNotMatched")}
          intent="black"
          outline={false}
          onClick={handleUpdateStatus}
          className={styles.matchPaymentsInfoCardContentDarkButton}
          disabled={isLoading}
        />

        <ModalAction
          direction="column"
          isOpen={showConfirmation}
          onClose={handleToggleConfirmation.bind(null, false)}
          intent="success"
          title={t("pages.payments.success")}
          content={() => (
            <div>{t("pages.payments.paymentMatchedSuccesfully")}</div>
          )}
          ctaBtns={[
            {
              text: t("close"),
              intent: "primary-light",
              onClick: handleToggleConfirmation.bind(null, false),
              disabled: isLoading,
            },
          ]}
        />
      </div>
    </>
  );
}
