import { Tooltip } from "components/Tooltip";

import { truncateString } from "lib/helpers";

export function Truncate({ value }: { value?: string }) {
  if (value && value.length < 20) {
    return value;
  } else {
    return (
      <div>
        <Tooltip
          text={value!}
          intent="Dark"
          style={{ transform: "translateY(-70%)" }}
        >
          {truncateString(value as string, {
            byChars: 20
          })}
        </Tooltip>
      </div>
    );
  }
}
