import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import widgetPanelSlice from "components/WidgetPanel/widgetPanelSlice";

import { presentationServiceAPISlice } from "lib/slices/presentationServiceAPISlice";
import languageSlice from "lib/slices/languageSlice";
import themeSlice from "lib/slices/themeSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // We are ignoring these to avoid the non-serializable Redux warning.
        // In our widgetsData, we have renderContent() and renderEditContent()
        // which cannot be serialized.
        ignoredActions: ["widgetPanel/setData", "widgetPanel/updateDataById"],
        ignoredPaths: ["widgetPanel.entities", "widgetPanel.deletedWidgets"],
      },
    }).concat(presentationServiceAPISlice.middleware),
  reducer: {
    widgetPanel: widgetPanelSlice,
    language: languageSlice,
    theme: themeSlice,
    [presentationServiceAPISlice.reducerPath]: presentationServiceAPISlice.reducer,
  },
});

setupListeners(store.dispatch);

export type IState = ReturnType<typeof store.getState>;

// Export RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
