export enum QUERY_KEYS {
  sort = "sort",
  pagination = "page",
  order = "order",
  desc = "desc",
  asc = "asc",
}

export function resolveState<T>(
  updater: T | ((old: T) => T),
  currentState: T,
): T {
  if (typeof updater === "function") {
    return (updater as (old: T) => T)(currentState);
  }
  return updater;
}

export function updateUrlWithParams(
  queryParam: string,
  value: string | undefined,
) {
  const url = new URL(window.location.href);

  if (value === undefined) {
    url.searchParams.delete(queryParam, value);
  } else {
    url.searchParams.set(queryParam, value);
  }

  window.history.replaceState({}, "", url.toString());
}
