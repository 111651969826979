import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import validator from "@rjsf/validator-ajv8";

import { Button } from "components/Button";
import { Form } from "components/Form";
import { PasswordGenerateWidget } from "components/Form/PasswordGenerateWidget";

import {
  useCreateMemberMutation,
  useEditMemberMutation,
  useGetClientsQuery,
  useGetMembersQuery,
} from "lib/slices/presentationServiceAPISlice";

import styles from "./FormCreateUser.module.scss";
import { ModalAction } from "components/Modal";

type Props = {
  defaultValues?: Member;
  onResetRowsSelection?: () => void;
  closeModal?: () => void;
};

export function FormCreateUser({ defaultValues, onResetRowsSelection, closeModal }: Props) {
  const { t } = useTranslation();
  const { data: clients } = useGetClientsQuery();
  const [createMember, { isLoading }] = useCreateMemberMutation();
  const [editMember, { isLoading: isLoadingEdit }] = useEditMemberMutation();
  const [errors, setErrors] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessages, setConfirmationMessages] = useState<{
    created: React.ReactNode;
    edited: string;
  }>({
    created: "",
    edited: ""
  });

  const { refetch } = useGetMembersQuery();

  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "role",
    "client",
    "tempPassword",
    "enabled"
  ];
  const editRequiredFields = defaultValues
    ? requiredFields.filter((field) => field !== "tempPassword")
    : requiredFields;

  const formData = {
    onSubmit: (data: any) => {
      if (defaultValues) {
        editMember({ ...data.formData, userId: defaultValues.id })
          .unwrap()
          .then(async () => {
            await refetch();
            setShowConfirmation(true);
            setConfirmationMessages({
              created: "",
              edited: t("pages.members.userEditedSuccessfully")
            });
          })
          .catch((error) => setErrors(error));

        return;
      }

      createMember(data.formData)
        .unwrap()
        .then(async () => {
          await refetch();
          setShowConfirmation(true);
          setConfirmationMessages({
            created: <Trans
              i18nKey="pages.members.userCreatedSuccessfully"
              values={{
                firstName: data.formData.firstName,
                lastName: data.formData.lastName,
                clientName: data.formData.client,
                role: data.formData.role,
              }}
             />,
            edited: ""
          });
          onResetRowsSelection?.();
        })
        .catch((error) => setErrors(error));
    },
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        text: t(defaultValues ? "save" : "create"),
        disabled: isLoading || isLoadingEdit,
      },
      requiredFields: {
        "ui:field": "requiredFieldsCustom",
      },
      firstName: {
        "ui:widget": "text",
        "ui:placeholder": "John",
        "ui:width": "50%",
      },
      lastName: {
        "ui:widget": "text",
        "ui:placeholder": "Smith",
        "ui:width": "50%",
      },
      email: {
        "ui:widget": "email",
        "ui:placeholder": "johndoe@example.com",
        "ui:width": "50%",
      },
      role: {
        "ui:widget": "select",
        "ui:width": "50%",
      },
      client: {
        "ui:widget": "select",
        "ui:width": "50%",
      },
      enabled: {
        "ui:widget": "radio",
        "ui:options": {
          inline: true,
        },
      },
      tempPassword: {
        "ui:widget": (props: any) => (
          <PasswordGenerateWidget {...props} customUpdate={props.onChange} />
        ),
        "ui:placeholder": "User will have to make a new password on sign-in",
        "ui:width": "50%",
        "ui:disabled": !!defaultValues?.id,
        "ui:hidden": defaultValues ? true : false,
      },
    },
    fields: {
      requiredFieldsCustom: () => (
        <p style={{ color: "#E51C00", textAlign: "center" }}>
          {t("pages.members.requiredFields")}
        </p>
      ),
    },
    schema: {
      type: "object",
      required: editRequiredFields,
      properties: {
        requiredFields: {
          type: "string",
          title: " ",
        },
        firstName: {
          type: "string",
          title: t("formInviteMember.name"),
          default: defaultValues?.firstName,
          maxLength: 100,
        },
        client: {
          type: "string",
          title: t("formInviteMember.client"),
          default: defaultValues?.groups[0],
          enum: clients ? clients.data.map((client) => client.slug) : [],
          enumNames: clients ? clients.data.map((client) => client.name) : [],
        },
        lastName: {
          type: "string",
          title: t("formInviteMember.surname"),
          default: defaultValues?.lastName,
          maxLength: 100,
        },
        role: {
          type: "string",
          title: t("formInviteMember.role"),
          default: defaultValues?.role,
          enum: [
            "client-operator",
            "client-manager",
            "customer-operator",
            "key-account-manager",
          ],
        },
        email: {
          type: "string",
          title: t("formInviteMember.email"),
          default: defaultValues?.email,
          maxLength: 50,
        },
        tempPassword: {
          type: "string",
          title: t("formInviteMember.tempPassword"),
          minLength: 8,
        },
        enabled: {
          type: "boolean",
          title: t("formInviteMember.enableUser"),
          default: defaultValues?.enabled,
          enum: [true, false],
        },
      },
    },
  };

  const handleToggleModal = (show: boolean) => {
    setShowConfirmation(show);
    closeModal?.();
  };

  return (
    <>
    <Form id="new-member-form" {...formData} asyncErrors={errors} noHtml5Validate={true}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          className={styles.formButton}
          intent="primary-light"
          text={t("cancel")}
          onClick={closeModal}
        />

          <Button
            className={styles.formButton}
            intent="black"
            icon={{ name: "add", intent: "white", size: 12 }}
            type="submit"
            text={
              defaultValues?.id
                ? t("formInviteMember.edit")
                : t("formInviteMember.create")
            }
          />
        </div>
      </Form>

      <ModalAction
        direction="column"
        title={t("pages.superAdmin.clients.success")}
        content={() => confirmationMessages.created ? <p>{confirmationMessages.created}</p> : <p>{confirmationMessages.edited}</p>}
        isOpen={showConfirmation}
        intent="success"
        ctaBtns={[
          { text: t("pages.superAdmin.clients.confirm"),
            intent: "primary-light",
            fill: true,
            onClick: () => handleToggleModal(false)
          },
        ]}
        onClose={() => handleToggleModal(false)}
      />
    </>
  );
}
