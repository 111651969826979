import { RInioButton } from "ui-core";

import { InvoiceActivity } from "components/InvoiceActivity/InvoiceActivity";
import { Text } from "components/Text";

import styles from "./CaseHistory.module.scss";

type Props = {
  invoice: Case;
};

export function CaseHistory({ invoice }: Props) {
  const btnPdfDownload = invoice.pdfURL ? (
    <a
      href={invoice.pdfURL}
      download={`invoice-${invoice.id}`}
      style={{ marginTop: 42 }}
    >
      <RInioButton
        intent="tertiary"
        text="Download PDF"
        icon="download"
        textLeft
      />
    </a>
  ) : (
    <div style={{ marginTop: 42 }}>
      <RInioButton
        intent="tertiary"
        text="Download PDF (no PDF link found)"
        icon="download"
        textLeft
        disabled
      />
    </div>
  );

  if (invoice.histories.length === 0) {
    return (
      <div style={{ textAlign: "center", height: "100%" }}>
        <Text as="p" style="body">
          No invoice history
        </Text>

        {btnPdfDownload}
      </div>
    );
  }

  return (
    <div className={styles.caseInformationInvoiceHistory}>
      <InvoiceActivity timeline={invoice.histories} />
      {btnPdfDownload}
    </div>
  );
}
