import { countryCodeMapper } from "utils/i18n";

export function formatNumber(num: number, shortenNumbers = false): string {
  const formatter = new Intl.NumberFormat("en-US", {
    notation: shortenNumbers ? "compact" : "standard",
  });

  return formatter.format(num);
}

export function formatCurrency(num: number, currency = "EUR"): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "code",
    currencySign: "accounting",
  }).format(num);
}

export function formatCurrencyWithNumberFormat(
  amount: string | number,
  lng = "en",
  currency?: string,
  lowercased?: boolean,
) {
  let cur;
  try {
    cur = new Intl.NumberFormat(
      `${countryCodeMapper[lng as keyof typeof countryCodeMapper]}`,
      {
        style: "currency",
        currency: currency,
      },
    ).format(Number(amount));
  } catch {
    cur = `${amount} ${currency}`;
  }

  if (lowercased) return cur?.toLowerCase();

  return cur;
}
