import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";

import styles from "./Tooltip.module.scss";

type Props = {
  title?: string;
  text: string;
  children: ReactNode;
  intent?: "Dark" | "Light";
  style?: CSSProperties;
  showAbove?: boolean;
};

export function Tooltip({
  title,
  text,
  intent = "Light",
  style = {},
  showAbove,
  children,
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipContainerRef = useRef<HTMLDivElement>(null);

  // Default tooltip to start from the left
  useEffect(() => {
    if (!tooltipRef.current) {
      return;
    }

    tooltipRef.current.style.left = "auto";
    tooltipRef.current.style.right = "0";
  }, [tooltipRef]);

  // Hides tooltip on scroll (needed for mobile)
  useEffect(() => {
    const main = document.getElementsByTagName("main")[0];
    const hideTooltip = () => {
      setShowTooltip(false);
    };

    main.addEventListener("scroll", hideTooltip);

    tooltipContainerRef.current?.addEventListener("mouseleave", hideTooltip);

    return () => {
      main.removeEventListener("scroll", hideTooltip);
      tooltipContainerRef.current?.removeEventListener(
        "mouseleave",
        hideTooltip,
      );
    };
  }, []);

  const onMouseEnter = (e: any) => {
    setShowTooltip(true);
    if (!tooltipRef.current) {
      return;
    }
    // Checks if tooltip will overflow
    if (
      e.currentTarget.getBoundingClientRect().left +
        tooltipRef.current.getBoundingClientRect().width >
      window.innerWidth
    ) {
      tooltipRef.current.style.left = "auto";
      tooltipRef.current.style.right = "0";
    } else {
      tooltipRef.current.style.left = "-12px";
      tooltipRef.current.style.right = "auto";
    }

    if (showAbove) {
      tooltipRef.current.style.left = "50%";
      tooltipRef.current.style.top = "-50%";
    }
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  const onTouch = (e: any) => {
    if (!tooltipRef.current) {
      return;
    }

    setShowTooltip(!showTooltip);
    tooltipRef.current.style.position = "fixed";
    tooltipRef.current.style.top = `${e.targetTouches[0].clientY - 12}px`;
    tooltipRef.current.style.width = "90%";
    tooltipRef.current.style.right = "8px";
  };

  return (
    <div className={styles.tooltipContainer} ref={tooltipContainerRef}>
      <div onMouseEnter={onMouseEnter} onTouchStart={onTouch}>
        {children}
      </div>
      <div
        ref={tooltipRef}
        onMouseLeave={onMouseLeave}
        className={classNames(styles.tooltip, styles[`tooltip${intent}`], {
          [styles.tooltipShow]: showTooltip,
        })}
        style={style}
      >
        {title && (
          <div className={styles.header}>
            <Icon name="informationcircle" size={14} />
            <p>{title}</p>
          </div>
        )}
        <p>{text}</p>
      </div>
    </div>
  );
}
