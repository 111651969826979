import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { NoResults } from "components/NoResults/NoResults";
import { SearchBox } from "components/Searchbox";
import { Skeleton } from "components/Skeleton";

import { useDetectScrollBottom } from "lib/hooks/useDetectScrollBottom";
import { useSearchSpecificInvoicesQuery } from "lib/slices/presentationServiceAPISlice";

import styles from "./MatchPaymentsDetails.module.scss";
import { SearchedInvoice } from "./SearchedInvoice";
import { useDebouncing } from "lib/hooks/useDebouncing";

type Props = {
  paymentId: string;
  moveToMatchedPaymentsTab: () => void;
};

export function SuggestedInvoicesToMatch({
  paymentId,
  moveToMatchedPaymentsTab,
}: Props) {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [showOverlay, setShowOverlay] = useState(false);
  const debounce = useDebouncing();
  const [debounceDelay, setDebounceDelay] = useState(false);

  const [query, setQuery] = useState<{
    page: number;
    limit: number;
    value: string | null;
  }>({ page: 1, limit: 10, value: null });

  const { data, isFetching } = useSearchSpecificInvoicesQuery(
    query.value === ""
      ? `?page=${query.page}&limit=${query.limit}`
      : `?searchTerm=${query.value}&page=${query.page}&limit=${query.limit}`,
      {
        skip: query.value === null || query.value === ""
      }
  );

  const [fetchedData, setFetchedData] = useState<
    Pick<
      Case,
      | "id"
      | "customerName"
      | "dueDate"
      | "totalAmount"
      | "paymentReference"
      | "customerSSN"
    >[]
  >([]);


  useDetectScrollBottom({
    wrapperRef,
    callbackReachBottom: useCallback(() => {
      setShowOverlay(false);
      if (
        data &&
        data?.rows.length > 0 &&
        data.totalItems > fetchedData.length
      ) {
        setQuery((prev) => ({ ...prev, page: query.page + 1 }));
      }
    }, [data, fetchedData]),
    resetCallback: () => {
      setShowOverlay(true);
    },
  });

  useEffect(() => {
    if (data && data.rows) {
      console.log(data.rows);
      setFetchedData((prev) => {
        if (data.rows.length > 0) {
          return [...prev, ...data.rows];
        } else {
          return [];
        }
      });
    }

    setShowOverlay(data && data.rows ? data.rows.length > 1 : false);
  }, [data]);

  const handleChangeSearchInput = (value: string) => {
    if(value !== "") {
      setDebounceDelay(true);

      debounce(() => {
        setQuery({ ...query, page: 1, limit: 10, value });
        setFetchedData([]);
        setShowOverlay(false);
        setDebounceDelay(false);
      }, 500);
    } else {
      setQuery({ ...query, page: 1, limit: 10, value });
      setFetchedData([]);
      setShowOverlay(false);
      setDebounceDelay(false);
    }
  };

  const showSkeleton = isFetching || debounceDelay;
  const showBottomOverlay = showOverlay && query.value && fetchedData.length > 1;
  const showNoResults = ((!isFetching && !debounceDelay && fetchedData?.length === 0));

  return (
    <div className={styles.searchMatchInvoices}>
      <header className={styles.matchPaymentsColumnHeader}>
        <h4>{t("pages.payments.suggestedInvoicesToMatch")}</h4>
      </header>
      <div className={styles.searchMatchInvoicesInput}>
        <SearchBox
          placeholder={t("pages.payments.searchInvoicePlaceholder")}
          value={query.value === null ? "" : query.value}
          onChange={handleChangeSearchInput}
        />
      </div>
      <div ref={wrapperRef} className={styles.searchMatchInvoicesResults}>
        {showNoResults && (
          <NoResults
            text={t("pages.payments.searchForSuggestedCases")}
            className={styles.searchMatchInvoicesResultsEmpty}
          />
        )}

        {!debounceDelay && query.value &&
          fetchedData?.map((invoice) => (
            <SearchedInvoice
              key={invoice.id}
              paymentId={paymentId}
              moveToMatchedPaymentsTab={moveToMatchedPaymentsTab}
              {...invoice}
            />
          ))}

        {showSkeleton && (
          <Skeleton
            height={showSkeleton ? "100%" : "auto"}
            customClasses={styles.customSkeletonLoaderClasses}
          />
        )}
      </div>

      {showBottomOverlay && <div className={styles.overlay}></div>}
    </div>
  );
}