import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  TableOptions,
  SortingState,
  ColumnFiltersState,
  ColumnDef,
  CoreRow,
} from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ModalAction } from "components/Modal";

import { inDateRange } from "lib/dateUtils";

import styles from "./Table.module.scss";
import { TableBody } from "./TableBody";
import { TableTop } from "./TableTop";

interface TableProps<TData> {
  data: TData[] | undefined;
  isLoading?: boolean;
  columns: ColumnDef<any, any>[];
  filters?: any;
  header?: boolean;
  headerCta?: React.ReactNode;
  mobileElement?: ({ rowData }: { rowData: any[] }) => JSX.Element;
  children?: React.ReactNode;
  CustomTable?: (props: { table: any }) => JSX.Element;
  pageSize?: number;
  setData?: (data: React.SetStateAction<TData[]>) => void;
  selectedRows?: CoreRow<TData>[];
  deleteRows?: boolean;
  onRowDelete?: (rows: CoreRow<TData>[]) => void;
  searchPlaceholder?: string;
  borderedTable?: boolean;
}

export function Table<TData>({
  data = [],
  columns,
  filters,
  header,
  headerCta,
  mobileElement,
  children,
  CustomTable,
  pageSize = 10,
  setData,
  selectedRows = [],
  deleteRows,
  onRowDelete,
  searchPlaceholder,
  borderedTable,
  isLoading,
}: TableProps<TData>) {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>();
  const [showDeleteModal, setDeleteModalOpen] = useState(false);
  const [deleteUserConfirmation, setDeletedUserConfirmation] = useState({
    visibility: false,
    deletedUsers: [] as CoreRow<TData>[]
  });

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      inDateRange,
    },
    initialState: {
      pagination: {
        pageSize,
      },
    },
    state: {
      globalFilter,
      columnFilters,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    meta: {
      resetRow: (rowIndex: number, rowData: any) => {
        setData?.((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return rowData;
            }
            return row;
          }),
        );
      },
    },
  } as TableOptions<TData>);

  const headers = columns
    .map((column) => column.header)
    .filter((c) => c)
    .join(", ");

  if (CustomTable) {
    return <CustomTable table={table} />;
  }

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.tableMain}>
          <div>
            {header && (
              <TableTop
                table={table}
                ctaBtn={headerCta}
                filters={filters}
                deleteRows={deleteRows}
                selectedRows={selectedRows}
                searchPlaceholder={
                  searchPlaceholder ?? t("table.searchPlaceholder", { headers })
                }
                setDeleteModalOpen={setDeleteModalOpen}
              />
            )}
            <TableBody
              table={table}
              MobileElement={mobileElement}
              selectedRows={selectedRows}
              borderedTable={borderedTable}
              isLoading={isLoading}
            >
              {children}
            </TableBody>
          </div>
        </div>
      </div>

      <ModalAction
        title="Attention"
        direction="column"
        content={() => {
          return (
            <>
              <p>Are you sure you want to delete{" "}
              {selectedRows.map((row, i, arr) => {
                return (
                  <span key={row.id} style={{display: "block"}}>
                    {/* TODO: Fix me to be dynamic */}
                    {/* @ts-expect-error type error */}
                    <strong>{`${row.original.firstName} ${row.original.lastName} - ${row.original.clientName} - ${row.original.role}`}</strong>
                    {i === arr.length - 1 ? "?" : i === arr.length - 2 ? " and " : ", "}
                  </span>
                );
              })}
              </p>
            </>
          );
        }}
        isOpen={showDeleteModal ? true : false}
        intent="danger"
        ctaBtns={[
          { text: t("close"), onClick: () => setDeleteModalOpen(false) },
          {
            text: t("Delete User(s)"),
            onClick: () => {
              onRowDelete?.(selectedRows);
              setDeleteModalOpen(false);
              setDeletedUserConfirmation({
                visibility: true,
                deletedUsers: selectedRows
              });
            },
            intent: "danger",
          },
        ]}
        onClose={() => setDeleteModalOpen(false)}
      />

      <ModalAction
        title=""
        direction="column"
        content={() => {
          return (
            <>
             <p>User{deleteUserConfirmation.deletedUsers.length > 1 ? "s " : " "}
              {deleteUserConfirmation.deletedUsers.map((row, i, arr) => {
                return (
                  <strong key={row.id} style={{display: i < arr.length - 2 ? "block" : "initial"}}>
                    {/* TODO: Fix me to be dynamic */}
                    {/* @ts-expect-error type error */}
                    {`${row.original.firstName} ${row.original.lastName} - ${row.original.clientName} - ${row.original.role}`}
                  </strong>
                );
              })} are deleted.
              </p>
            </>
          );
        }}
        isOpen={deleteUserConfirmation.visibility}
        intent="info"
        ctaBtns={[
          { text: t("close"), onClick: () => setDeletedUserConfirmation({
            visibility: false,
            deletedUsers: []
          }) }
        ]}
        onClose={() => setDeletedUserConfirmation({
          visibility: false,
          deletedUsers: []
        })}
      />
    </>
  );
}
