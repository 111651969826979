import { ChangeEvent } from "react";

import { WidgetProps } from "@rjsf/utils";
import { RInioInputTel } from "ui-core";

export function PhoneWidget(props: WidgetProps) {
  const {
    id,
    placeholder,
    disabled,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    value,
    label,
    uiSchema,
  } = props;

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(value === "" ? undefined : value.replace(" ", ""));
  };
  const onTextBlur = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => onBlur?.(id, value);
  const onTextFocus = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => onFocus?.(id, value);

  return (
    <RInioInputTel
      label={label}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChangeOverride || onTextChange}
      onBlur={onTextBlur}
      onFocus={onTextFocus}
      editable={uiSchema?.["ui:editable"]}
      readonly={uiSchema?.["ui:editable"]}
    />
  );
}
